import React from "react"
import styled from "styled-components";
const FormPadrao = styled.div`

 width: 100%;
 padding-top: 10px;
 color: #F49519;
 
    .containerTitulo{
        display: flex;
        width: 100%;

    }
    .checks-textarea{
        display: flex;
        width: 100%;

    }
 .containerTitulo input{
    //max-width: 520px;
    width: 100%;
    border-radius: 9px;
    height: 26px;
    background-color: #F4951926;
    border: 1px solid #F49519;
    padding: 5px;
 }
 .containerTitulo input::placeholder {
  color: #F49519;
}
textarea{
    margin-top: 15px;
   // max-width: 520px;
    width: 100%;
    border-radius: 9px;
    height: 136px;
    background-color: #F4951926;
    border: 1px solid #F49519;
    padding: 5px;
 }
  textarea::placeholder {
  color: #F49519;
}
span{
        font-size: 14px;
}
.containerCheck{
    display: flex;
    max-width: 458px;
    width: 100%;
    flex-wrap: wrap;

}
.checks{
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: start;
    background-image: linear-gradient(to right, #F49519, #D35D2E );
    border-radius: 9px;
    color: #fff!important;
    height: 40px;
    padding: 1px 3px;
   // flex-wrap: wrap;
    margin-top: 20px;
    max-width: 138px;
    width: 100%;
   

}
.line{
    height: 100%;
    border-left: 3px solid #000 ;
    margin: 0 9px 0 9px;
}

`

function AtvComplFormCheck({ change = {},click={}, type = 0, placeholder = "", checkUm = "", checkDois = "",checkTres = "",checkQuatro = "",title= ''  }) {

    return (
        <FormPadrao>
            {
                type == 1 ? '' : 
            
            <div className="containerTitulo">
                        <input onChange={change} type="text" name="titulo" placeholder={title == '' ? "Titulo" : title} />

            </div>
            }
            <div className="containerCheck">
                <div className="checks">
                    <input name="atv" type="radio" value={checkUm} onClick={click}/>
                    <div className="line"> </div>
                    <span> <b>{checkUm}</b></span>
                    
                </div>
                <div className="checks">
                    <input name="atv" type="radio" value={checkDois} onClick={click} />
                    <div className="line"></div>
                    <span><b>{checkDois}</b></span>

                    

                </div>
                <div className="checks">
                    <input name="atv" type="radio" value={checkTres} onClick={click} />
                    <div className="line"></div>
                    <span><b>{checkTres}</b></span>
                    
                </div>
                <div className="checks">
                    <input name="atv" type="radio" value={checkQuatro} onClick={click} />
                    <div className="line"></div>
                    <span><b>{checkQuatro}</b></span>
                    
                </div>

            </div>
            <div className="checks-textarea">
                 <textarea onChange={change} name={"descricao"} placeholder={placeholder}></textarea>
            </div>
        </FormPadrao>

    )
}

export default AtvComplFormCheck;