

import { useEffect, useState, Fragment } from 'react'






import Routes from "./routes";



function App(props) {

    useEffect(() => {
      // Verifica se a página está carregando no protocolo HTTP
      /*
      if (window.location.protocol === 'http:') {
        // Redireciona para a versão HTTPS da mesma URL
        window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
      }*/
 

   
    })

  return (
    <>
  
          <Routes />

      
    
      

    </>
  );
    }
    const styles = {
      container: {

        width: '100%',
        display: 'flex',
        maxWidth: 1403,
        flexDirection: 'column',
        padding: 28,
        background: '#fff',
        border: '1px solid rgb(0,0,0,0.5)',
        borderRadius: 13,
        position: 'relative',
        boxShadow: 'black 11px 7px 6px 9px',
        height: 585

      },
      containerBackdrop: {
        display: 'flex',
        width: '100%',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000',
        height: '100%',
        top: 0,
        opacity: 0.7
      },
      containerText: {
        fontFamily: "sans-serif",
        textAlign: 'center',
        marginTop: 71,
        marginBlockEnd: 0

      }
    }


export default App;
