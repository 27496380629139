import React, { useState } from "react";
import styled from "styled-components";
import Banner from "../../components/banner";
import Header from "../../components/component";
import AvisoComponet from "../../components/aviso";

const AvisoWrapper = styled.div`

`

function Aviso(params) {
    const [aviso, setAviso] = useState([

        { data: '2023-03-02', descricao: 'IMPORTANTE | Título de exemplo de aviso pop-up', flg_aberto: 1 },
        { data: '2023-03-05', descricao: ' Título de exemplo de aviso pop-up', flg_aberto: 0 },
        { data: '2023-03-22', descricao: ' Título de exemplo de aviso pop-up', flg_aberto: 0 },
        { data: '2023-03-29', descricao: ' Título de exemplo de aviso pop-up', flg_aberto: 1 }


    ])

    return (
        <AvisoWrapper>
            <Header />
            <Banner button="Aviso" />
            <div className="containerItems">
                <AvisoComponet arr={aviso} />


            </div>
        </AvisoWrapper>

    )

}
export default Aviso