import React from "react"
import styled from "styled-components";
const FormPadrao = styled.div`

 width: 100%;
 padding-top: 10px;
 color: #F49519;

 input{
    max-width: 520px;
    width: 100%;
    border-radius: 9px;
    height: 26px;
    background-color: #F4951926;
    border: 1px solid #F49519;
    padding: 5px;
 }
 input::placeholder {
  color: #F49519;
}
textarea{
    margin-top: 15px;
    max-width: 520px;
    width: 100%;
    border-radius: 9px;
    height: 136px;
    background-color: #F4951926;
    border: 1px solid #F49519;
    padding: 5px;
 }
  textarea::placeholder {
  color: #F49519;
}


`

function AtvComplFormPadrao({change = {}, type = 0, placeholder = '', title= ''}){

    return(
        <FormPadrao>
            {
                type == 0 ? '' : 
           
                    <input onChange={change} type="text" name="titulo" placeholder={title == '' ? "Titulo" : title} />
            }
            <textarea onChange={change} name={"descricao"} placeholder={placeholder == '' ? 'Descrição das atividades realizadas e do aprendizado adquirido' : placeholder}></textarea>
        </FormPadrao>

    )
}

export default AtvComplFormPadrao;