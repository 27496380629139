import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react'
import api from '../../api';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageAvatars from '../avatar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import axios from "axios";
import CancelIcon from '@mui/icons-material/Cancel';
export default function BoxFormLicense(props) {
    const { setLoading, exit, edit, alertMsg } = props
    const [data, setData] = useState({ chave_ativacao: '', pessoa: '', fabricante: '', data_compra: '0000-00-00', empresa: '', obs: '', parceiro: '', produto: '', validade: '0000-00-00', qtd_licensa: 0, id_software: '', versaoSoft: '', valoLicensa: 0, file: [] })

    const [alert, setAlert] = useState({ type: 'success', msg: 'Obrigado por sua Avaliação' })
    const [alertVerify, setAlertVerify] = useState(false)
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const { 
        chave_ativacao,
        pessoa,
        fabricante,
        data_compra,
        empresa,
        obs,    
        validade,
        qtd_licensa,
        id_software,
        versaoSoft,
        file
         } = data
         
    const onchange = async (e) => {
        let { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    const ative = (status) => {
        const { ativo = true } = data
        if (ativo) {
            setData({
                ...data,
                ativo: status
            })
        } else {
            setData({
                ...data,
                ativo: status
            })

        }

    }

    const save = async () => {
        setLoading(true)
        if(edit!= ''){
            return await editLicense()
        }

        await axios.post(`${process.env.REACT_APP_API}/license`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data } = response
            setLoading(false)         
            await exit()
            await alertMsg('Licensa cadastrado com sucesso', 'success',2)

        }).catch( async error => {
            setLoading(false)
            console.log("erro", error)
            await alertMsg('Houver um error ao cadastrar o Licensa', 'error',2)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })

    }
    const editLicense = async  () =>{
        await axios.patch(`${process.env.REACT_APP_API}/license/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data } = response
            setLoading(false)
            await exit()
            await alertMsg('Licensa editado com sucesso', 'success', 2)

        }).catch(async error => {
            setLoading(false)
            console.log("erro", error)
            await alertMsg('Houver um error ao cadastrar o Licensa', 'error', 2)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })

    }
    const upload = async (e) => {
        const { files } = e.target
        let file = files[0]
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
       
       
        await axios.post(`http://admmelies.com.br/upload.php`, file, {
            headers: {                
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        })
            .then(async response => {     
                await alertMsg('Upload de foto com sucesso', 'success', 2)           
                
            })
            .catch(async error => {
                await alertMsg('Error: contact o suporte', 'error', 2)    
                console.log("erro", error)
               
              

            })
    }

    useEffect(() => {

        if (typeof edit != 'undefined'){
            setData(edit)
        }
    }, [])


    return (
        <div style={styles.container} >
            <div style={{ textAlign: 'center' }}>
                <h3 style={{ color: '#fff' }}>Adicionar uma nova Licença </h3>
            </div>
            <CancelIcon style={styles.cancel} onClick={exit} />
            <div style={styles.contet}>         

                <div style={styles.containerItems}>
                    <div style={styles.item}>
                        <TextField id="standard-basic" name="qtd_licensa" label="Quantidade de licensa" value={`${qtd_licensa}`} variant="standard" onChange={onchange} />

                    </div>
                    <div style={styles.item}>
                        <TextField id="standard-basic" label="Empresa " name="empresa" value={`${empresa}`} variant="standard" onChange={onchange} />
                    </div>
                </div>
                          
                <div style={styles.containerItems}>

                    <div style={styles.itemC}>
                        <TextField style={{ maxWidth: 200, width: '100%' }} id="standard-basic" label="Data da compra" type="date" value={data_compra} name="data_compra" variant="standard" onChange={onchange} />
                    </div>
                    <div style={styles.itemC}>
                        <TextField id="standard-basic" type="date" value={validade} name="validade" label="Validade" variant="standard" onChange={onchange} />
                    </div>

                </div>
                <div style={styles.containerItems}>
                    <div style={styles.item}>
                        <TextField id="standard-basic" label="Pessoa" name="pessoa" value={`${pessoa}`} variant="standard" onChange={onchange} />
                    </div>
                    <div style={styles.item}>
                        <TextField id="standard-basic" label="Chave ativação" name="chave_ativacao" value={`${chave_ativacao}`} variant="standard" onChange={onchange} />
                    </div>
                </div>
                {/* <div style={styles.containerItems}>

                    <div style={styles.itemC}>
                        <TextField style={{ maxWidth: 450, width: '100%' }} id="standard-basic" label="Versão do sfw" name="versaoSoft" value={`${versaoSoft}`} variant="standard" onChange={onchange} />
                    </div>

                </div>  */}
              
               
               
                <div style={{...styles.containerItemsC, ...{justifyContent: 'center'}}}>
                    <div style={styles.itemC}>
                        <TextField
                            
                            id="standard-multiline-static"
                            label="Campo para obs"
                            onChange={onchange}
                            name="obs"
                            value={`${obs}`}
                            multiline
                            style={styles.textarea}
                            rows={4}
                            //defaultValue="Default Value"
                            variant="standard"
                        />
                    </div>
                </div>
               

                <div style={styles.containerItems}>
                   {/* <div style={styles.item}>
                        <Button variant="contained" component="label">
                            Upload de arquivo
                            <input hidden  onChange={upload} multiple type="file" />
                        </Button>
                    </div>  */}
                    <div style={styles.item} >
                        <Button variant="contained" color="success" onClick={() => save()}>
                            Gravar
                        </Button>
                    </div>
                    

                </div>




            </div>


        </div>
    )


}
const styles = {
    container: {
        background: '#333333',
        position: 'absolute',
        top: '4px',
        left: '470px',
        borderRadius: 8,
        width: 524,
        padding: 5
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    containerItems: {
        padding: 5,
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%'
    },
    input: {
        borderRadius: 5,
        padding: 5,

    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    itemC: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'initial',
        width: '88%'

    },
    itemCH: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'initial',

    },
    containerItemsC: {
        padding: 5,
        display: 'flex',
        justifyContent: 'initial',
        width: '100%'
    },
    textarea: {
        margin: 10,
        borderRadius: 5,
        width: '100%',

    },
    cancel: {
        position: 'absolute',
        top: 5,
        right: 7,
        color: '#fff',
        cursor: 'pointer'
    }



}