import * as React from 'react';
import { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Moment from 'react-moment';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";

export default function DenseTable(props) {
    const { software, edit,alertMsg} = props   
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
   
    

    useEffect(() => {
      
        getSoftware()
       

    },[])
    const getSoftware = async () => {

        await axios.get(`${process.env.REACT_APP_API}/softwareTable `, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {} } = response.data
            await setData(data)          
        }).catch(error => {
            console.log("erro", error)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }

    const apagarItem = async (id) => {
        await axios.delete(`${process.env.REACT_APP_API}/software/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {} } = response.data
            await alertMsg('Software apagado com sucesso', 'success', 1)
        }).catch(async error => {
            console.log("erro", error)
            await alertMsg('Houver um error ao cadastrar o software', 'error', 1)

        })

    }
    const handleClose = () => {
        setOpen(false);
    };


    
  
    
    return (
        <>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>                       
                        <TableCell align="center">Nome </TableCell>
                        <TableCell align="center">Fabricante</TableCell>
                        <TableCell align="center">Versão</TableCell>
                        <TableCell align="center">OBS</TableCell>
                        <TableCell align="center">Delete</TableCell>                     
                    </TableRow>
                </TableHead>
                <TableBody>
                    {software.map((row) => {
                       
                        return (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}                                
                                style={{cursor: 'pointer'}}
                                
                            >
                                <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                    {row.nome}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                    {row.empresa}
                                </TableCell>
                             
                                <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                    {row.versao}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                    {row.obs}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <DeleteIcon onClick={() => apagarItem(row.id)} style={{ cursor: 'pointer' }} />
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
     
      </>
    );
}