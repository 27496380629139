import * as React from 'react';
import { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Moment from 'react-moment';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
export default function DenseTable(props) {
    const { license,edit,alertMsg } = props
   
   
    
    useEffect(() => {
        let dateAtual = new Date();
     
        
       // setDate(dateAtual)
        
    },[])


    const apagarItem = async (id) =>{
        await axios.delete(`${process.env.REACT_APP_API}/software/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            await alertMsg('Licensa Apagada com sucesso', 'success', 2)


        }).catch(async error => {
            console.log("erro", error)
            await alertMsg('Error: contact o suporte', 'error', 2)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })

    }
    
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                      
                        <TableCell align="center">Empresa</TableCell>
                        <TableCell align="center">Quantidade de licensa </TableCell>                       
                        <TableCell align="center">Data Comprar</TableCell>
                        <TableCell align="center">Validade</TableCell>                        
                        <TableCell align="center">Pessoa  </TableCell>
                        <TableCell align="center">Chave de Ativação</TableCell>                       
                        <TableCell align="center">OBS  </TableCell>
                        <TableCell align="center">Ativado   </TableCell>
                        <TableCell align="center">Delete   </TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {license.map((row) =>{
                        let dateAtual = new Date();
                        dateAtual = dateAtual.toISOString().split("T")[0];
                        const diffInMs = new Date(row.validade.split("T")[0]) - new Date(dateAtual)
                        const days = diffInMs / (1000 * 60 * 60 * 24);
                      
                    
                    return(
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={{ background: dateAtual > row.validade ? 'red' : days <= 15 ? 'Yellow' : '#fff', cursor: 'pointer' }}
                            
                        >
                            <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                {row.empresa}
                            </TableCell> 
                            <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                {row.qtd_licensa}
                            </TableCell>                                                                        
                            <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                <Moment format="DD/MM/YYYY">
                                    {row.data_compra}
                                </Moment>                               
                            </TableCell> 
                            <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                <Moment format="DD/MM/YYYY">
                                    {row.validade}
                                </Moment>
                               
                            </TableCell>
                            <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                {row.pessoa}
                            </TableCell>  
                            <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                {row.chave_ativacao}
                            </TableCell> 
                            <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                {row.obs}
                            </TableCell> 
                            <TableCell component="th" scope="row" align="center" onClick={() => edit(row.id)}>
                                {row.ativo == 1 ? 'Sim' : 'Não'}
                            </TableCell>                
                            <TableCell component="th" scope="row" >
                                <DeleteIcon onClick={() => apagarItem(row.id)} style={{ cursor: 'pointer'}}/>
                            </TableCell>                
                        </TableRow>
                    )})}
                </TableBody>
            </Table>
        </TableContainer>
    );
}