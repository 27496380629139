import React, { useState, useEffect } from "react"
import styled from "styled-components";
import axios from "axios";
import IconThree from "../../fotos/icones/brancos/brancos-03.png"
import AjudaComponente from "../../components/ajuda/ajuda";
import Banner from "../../components/banner";
import Header from "../../components/component";
import IconFour from "../../fotos/icones/brancos/brancos-05.png"


const CpaWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;
.containerItems{  
        max-width: 981px;     
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }
       h1{
        color: #fff
    }




.containernota{
    width: 100%;
    display: flex;
}
 
@media (max-width: 1800px){
    max-height: 272px;

}
@media (max-width: 599px){   
    h1{
        font-size: 18px;
    }
}

.containerLink{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.containerLink a, span{
    color: #fff;
    margin: 1px 0px 0px 5px;
    font-size: 18px;
    font-weight: 600;
    padding: 7PX;

}
.itemLink{
    display: flex;
    align-items: center;
}
.linkTitle{
    color: #fff;
    margin: 5px 0px 20px 14px;
    font-size: 22px;
    font-weight: 600;
}
`

function CPA() {



    return (
        <CpaWrapper>
            <Header />
            <Banner button="CPA" img={IconFour} />
            <div className="containerLink">

                <h3 className="linkTitle">CPA:</h3>
                <div className="itemLink">
                    <a style={{ color: '#fff' }} href="https://academiamelies.sharepoint.com/:b:/s/melies.mkt/EVOmVs-HNg9Ive7p8Ct9QZAB-zz89qQ0SpOD6hc7seQ7jw?e=3rtPzC">Novembro/23: 0800-CPA Respostas - 2023_11.pdf</a>
                </div>               
                <div className="itemLink">
                    <a style={{ color: '#fff' }} href="https://academiamelies.sharepoint.com/:b:/r/sites/DAO-AVISOSGERAIS/Documentos%20Compartilhados/General/CPA/0800-CPA%20Respostas%20-%202023_08.pdf?csf=1&web=1&e=fVCbmw" target="_blank">Agosto/23: 0800-CPA Respostas - 2023_08.pdf</a>

                </div>
                <div className="itemLink">
                    <a style={{ color: '#fff' }} href="https://academiamelies.sharepoint.com/:b:/r/sites/DAO-AVISOSGERAIS/Documentos%20Compartilhados/General/CPA/0800-CPA%20Respostas%20-%202023_07.pdf?csf=1&web=1&e=orCfr4">Julho/23: 0800-CPA Respostas - 2023_07.pdf</a>
                </div>
                <div className="itemLink">
                    <a style={{ color: '#fff' }} href="https://academiamelies.sharepoint.com/:b:/r/sites/DAO-AVISOSGERAIS/Documentos%20Compartilhados/General/CPA/0800-CPA%20Respostas%20-%202023_04.pdf?csf=1&web=1&e=P8ONNP">Abril/23: 0800-CPA Respostas - 2023_04.pdf</a>
                </div>
                <div className="itemLink">
                    <a style={{ color: '#fff' }} href="https://academiamelies.sharepoint.com/:b:/r/sites/DAO-AVISOSGERAIS/Documentos%20Compartilhados/General/CPA/0800-CPA%20Respostas%20-%202023_03.pdf?csf=1&web=1&e=GuzjHC">Março/23: 0800-CPA Respostas - 2023_03.pdf</a>
                </div>
                    <a style={{ color: '#fff' }} href="https://academiamelies.sharepoint.com/:b:/r/sites/DAO-AVISOSGERAIS/Documentos%20Compartilhados/General/CPA/0800-CPA%20Respostas%20-%202022_10.pdf?csf=1&web=1&e=Eu57Xv">Outubro/22: 0800-CPA Respostas - 2022_10.pdf</a>
                <div className="itemLink">
                </div>
                <div className="itemLink">
                    <a style={{ color: '#fff' }} href="https://academiamelies.sharepoint.com/:b:/r/sites/DAO-AVISOSGERAIS/Documentos%20Compartilhados/General/CPA/0800-CPA%20Respostas%20-%202022_09.pdf?csf=1&web=1&e=db9Ccf">Setembro/22: 0800-CPA Respostas - 2022_09.pdf</a>
                </div>


                <br />
                <br />
                <div className="itemLink">
                    <span> Envie seu comentário / dúvida / solicitação / reclamação / elogio:  <a style={{ color: 'rgb(244, 149, 25)' }} href="https://forms.office.com/r/MAaAQgraAQ" target="_blank">Link</a> </span>


                </div>
            </div>

        </CpaWrapper>
    )

}
export default CPA
