import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularIndeterminate from "../../components/loading";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Header from "../../components/component"
import Banner from "../../components/banner";
import IconFive from "../../fotos/icones/brancos/brancos-05.png"
const AutenticaWrapper = styled.div`
 max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;
body {
  background-color: #1c1c1c;
  margin:0; 
  padding:0; 
  height:100%;
  font-family: 'Poppins', sans-serif;
}
h3 {
  margin-top: 25px;;
  color: #fff;
  cursor: pointer;
}
   
.btn{
  border: 0px;
  background-color: #680dfe;
  width: 60%;
  text-align: center;
  color: #fff;
  padding: 7px 0;
  cursor: pointer;
  border-radius: 15px;
  margin-top: 30px;
}
.container-bg{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 0;
}
.container-bg .bg{
  width: 25%;
}
.container-bg .bg-esquerda{
  max-height: 499px;
  max-width: 313px;
}
.container-bg .bg-direita{
  max-height: 830px;
  max-width: 511px;
}
 
input, input[type="password"], input[type="text"] {
  border: none;
  padding: 9px 0px;
  max-width: 100%;
  width: 60%;
  font-size: 1em;
  min-width: 200px;
  color: #8a8a8a;
  background-color: #393939;
  padding-left: 15px;
  border-radius: 5px;
  margin-top: 15px;
  font-family: 'Poppins', sans-serif;
}

.center{
  margin:0 auto;
  width: 540px;
  text-align: center;
  height:100%;
  z-index: 1;
  position: relative;

}

.form1{
  margin-top:10%;
  display: block;
  float: left;
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}
.logo{
    margin-top: 0%;
    width: 200px;
}
.containerBackdrop{
    display: flex;
    width: 100vw;
    position: absolute;
    alignItems: center;
    justifyContent: center;
    background: #000;
    height: 100%;
    top: 0;
    opacity: 0.7;
    z-index: 999999999;
    left: 0;
    justify-content: center;
    align-items: center;
}
.css-i4bv87-MuiSvgIcon-root{
    color: #fff;
    position: absolute;
    right: -15px;
    right: 104px;
    top: 21px;
}
.containerItem{
    position: relative;
}
`
function RedefinirSenha() {
    const [data, setData] = useState({ nova_senha: '', confirma_senha: '' })
    const [alert, setAlert] = useState({ msg: '', openAlert: false, type: 'success' })
    const [loading, setLoading] = useState(false)
    const [seePassword, setPassword] = useState(false)
    const [confirmPassword, setchange] = useState(false)


    const change = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })

    }
    const enviar = async (e) => {
        e.preventDefault()
        await setLoading(false)
        const token = localStorage.getItem("token") 
        const { search } = window.location
        const takeUrl = search.split("?")
        const finalyUrl = takeUrl[takeUrl.length - 1]


        if (data.nova_senha == '' || typeof data.nova_senha == undefined || data.nova_senha == 'undefined') {
            await setLoading(false)
            await setAlert({ msg: "o campo 'nova senha' não pode ser vazio", openAlert: true, type: "warning" })
            setTimeout(async () => {
                await setAlert({ msg: "", openAlert: false })
            }, 4000)
            return


        }
        if (data.confirma_senha == '' || typeof data.confirma_senha == undefined || data.confirma_senha == 'undefined') {
            await setLoading(false)
            await setAlert({ msg: "o campo 'confirma senha' não pode ser vazio", openAlert: true, type: "warning" })
            setTimeout(async () => {
                await setAlert({ msg: "", openAlert: false })
            }, 4000)
            return


        }
        if (data.nova_senha !== data.confirma_senha) {
            await setLoading(false)
            await setAlert({ msg: "As senhas digitadas não coincidem", openAlert: true, type: "warning" })
            setTimeout(async () => {
                await setAlert({ msg: "", openAlert: false })
            }, 4000)
            return
        }
        data.token = finalyUrl;
        console.log('aqui',token)
        await axios.post(`${process.env.REACT_APP_API}/redefini-senha `, data, {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET',
            'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {} } = response
            const { status = false, erroCode = 0 } = data

            if (status) {
                await setLoading(false)
                await setAlert({ msg: "Senha alterada com sucesso ", openAlert: true, type: "success" })
                setTimeout(async () => {
                    await setAlert({ msg: "", openAlert: false })
                   // window.location.href = "/autenticacao";
                }, 4000)

            }

            await setLoading(false)


        }).catch(async error => {
            await setLoading(false)
            await setAlert({ msg: "token expirado tente novamente!", openAlert: true, type: "warning" })
            setTimeout(async () => {
                await setAlert({ msg: "", openAlert: false })
               // window.location.href = "/autenticacao";
            }, 4000)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }


    return (
        <>
            <AutenticaWrapper>
            <Header />
                <Banner button="Trocar de senha"  />
                <div className="center">
              
                    <form>
                        <div className="form1">
                            <div className="containerItem">
                                <input type={seePassword ? 'text' : 'password'} name="nova_senha" id="login_site" placeholder="senha" onChange={change} />
                                {
                                    !seePassword ? <Visibility onClick={() => setPassword(true)} /> : <VisibilityOff onClick={() => setPassword(false)} />
                                }


                            </div>
                            <br />
                            <div className="containerItem">
                                <input type={confirmPassword ? 'text' : 'password'} name="confirma_senha" id="senha_site" placeholder="Confirma nova senha" onChange={change} />
                                {
                                    !confirmPassword ? <Visibility onClick={() => setchange(true)} /> : <VisibilityOff onClick={() => setchange(false)} />
                                }

                            </div>
                            <br />
                            <input type="submit" value="Enviar" className="btn" onClick={enviar} />
                        </div>
                    </form>

                    
                </div>
                {

                    alert.openAlert &&
                    <Alert variant="outlined" severity={alert.type}>
                        <label>{alert.msg}</label>
                    </Alert>

                }
                {
                    loading &&
                    <div className="containerBackdrop">
                        <CircularIndeterminate />
                    </div>
                }


            </AutenticaWrapper>

        </>
    )

}

export default RedefinirSenha