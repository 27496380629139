import React from "react";
import styled from "styled-components";
import BannerImg from "../../fotos/banner/banner3.jpeg"
const Banner = styled.div`


max-height: 228px;
width: 98%;
height: 100%;
border: 5px solid #F49519;
border-radius: 15px;
position: relative;
display: flex;


.botaoDireita{
    max-width: 180px;
    font-weight: bold;
     background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    width: 100%;
    padding: 5px;
    height: 30px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 5px;
    right: 5px;
    text-decoration: none;
  }

  .textButton{
    text-decoration: none;
    font-size: 13px
  }

img{
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    object-position: center;

}
.containerName{
    position: absolute;
    left: 3%;
    top: 50px;
    font-size: 20px;
    color: #fff;
}
.containerName h1{
    margin: 0;
     font-size: 48px;
}
.containerName span{
    font-size: 35px;
}
a{
    color:#fff;
}
.containerButton{
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to right, #F49519, #D35D2E );
    max-width: 200px;
    width: 100%;
    font-size: 20px;
    color: #fff;
    height: 30px;
    border-radius: 1px 25px 1px 5px;
    display: flex;
    align-items: center;
    padding: 14px;
}
.contentItem{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;

}
.containerButton .contentItem .item{
    width: 100%;
}
.containerButton .contentItem img{
    width: 30%;
}
@media (max-width: 1800px){
    width: 97%;

}
@media (max-width: 1024px){
    max-width: 888px;

}
@media (max-width: 959px){
    max-width: 809px;

}

@media (max-width: 768px){
    max-width: 577px;
    width: 100%;

}
@media (max-width: 599px){
    max-width: 408px;
   

}
@media (max-width: 405px){
    max-width: 274px;
    .containerName h1 {         
        font-size: 30px;
    }
    .containerName span {
        font-size: 28px;
    }
     .containerName {
        left: 3%;
        top: 128px;

    }
   

}
@media (max-width: 399px){
 .containerName {   
        top: 54px;
         font-size: 16px;
    }
   .containerName span {
         font-size: 18px;
    }

}

`

function BannerAll({ text = null, button = '', img = null }) {

    return (
        <>
            <Banner>
                {
                    text != null ?
                        <div className="containerName">
                            <span>Bem-vindo</span>
                            <h1>{text}</h1>
                        </div>
                        :
                        <div className="containerButton">
                            <div className="contentItem">
                                {img != null &&
                                    <img src={img} />

                                }
                                {
                                    button != '' &&
                                    button

                                }


                            </div>


                        </div>

                }
                <img src={BannerImg} />
                <a className="botaoDireita" href="agenda-professor" target="">
                    <text className="textButton">Atendimento Professor</text>
                </a>

            </Banner>

        </>
    )
}

export default BannerAll