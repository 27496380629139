import React, { useState, useEffect } from "react"
import styled from "styled-components";
import axios from "axios";
import IconThree from "../../fotos/icones/brancos/brancos-03.png"
import AjudaComponente from "../../components/ajuda/ajuda";
import Banner from "../../components/banner";
import Header from "../../components/component";
import IconFour from "../../fotos/icones/brancos/brancos-05.png"


const AgendaWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;
.containerItems{  
        max-width: 981px;     
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }
       h1{
        color: #fff
    }

table{
    margin: 5px;
    height: 100%;
   
}
tr{
    color: #fff
}

th{
    padding: 25px;    
    background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    
   
}

a{
    color: #fff!important;
    margin-left: 12px;
}


.containerNotas{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.materia{
    color: #000; 
    background-color: #F49519;
    padding: 10px 15px;
}
.nota{
    color: #F49519;
      
    background-color: #302114;
}
.semestre{
 border-radius: 20px 0px 0px 0px;
}

.media{
 border-radius: 0px 20px 0px 0px;
}
.footerFalta{
    border-radius: 0px 0px 20px 0px;
}
.meio{
    border-radius: 0px 0px 0px 20px;
}
.agenda{
     background-image: linear-gradient(to right,  #D35D2E, #F49519 );
     text-decoration: none;
    max-width: 114px;
    width: 100%;
    color: #000;
    border-radius: 5px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.containernota{
    width: 100%;
    display: flex;
}
 
@media (max-width: 1800px){
    max-height: 272px;

}
@media (max-width: 599px){   
    h1{
        font-size: 18px;
    }
}
`

function Agenda() {
    const [booking, setBookin] = useState([
        { name: 'Adam Stamm', 
        disciplina: 'Generalista 3D', 
        link_online: 'https://outlook.office.com/bookwithme/user/a837a04838e64d7a9faaf67614e7bc7f%40eba1c994-c3c8-4e60-b3d7-aa339e9ed490/meetingtype/b4b292ac-f8f5-4019-93bd-26f4642c9b82?anonymous', 
        link_presencial: 'https://outlook.office.com/bookwithme/user/a837a04838e64d7a9faaf67614e7bc7f@melies.com.br?anonymous&ep=pcard', link_atendimento: '' },
        
        { name: 'Ana Vidal', disciplina: 'Ling. e Prod. Audiovisual', link_online: 'https://outlook.office.com/bookwithme/user/81fa55124e1840ae8584669c314fec2a@melies.com.br?anonymous&ep=pcard', 
        link_presencial: 'https://outlook.office.com/bookwithme/user/81fa55124e1840ae8584669c314fec2a@melies.com.br?anonymous&ep=pcard', link_atendimento: '' },
        
        { name: 'Ana Manenti', 
        disciplina: 'Textura, Luz e Render 3D', 
        link_online: 'https://outlook.office.com/bookwithme/user/58f2f15402094939b1910c730a61b151%40melies.com.br/meetingtype/e84642ce-da83-4c0b-a815-448ba2c01d55?bookingcode=97d04e40-eb8d-4a3d-8003-5013dc86347c&anonymous&isanonymous=true', 
        link_presencial: 'https://outlook.office.com/bookwithme/user/58f2f15402094939b1910c730a61b151%40melies.com.br/meetingtype/dc8f9e4a-970e-4ac7-a782-b240ed61bd0b?bookingcode=63601174-d792-4b28-97d9-83b95d7ffa3c&anonymous&isanonymous=true' },
        
        { name: 'André Carvalho', disciplina: 'Semiótica e História da Arte', link_online: '', link_presencial: '', link_atendimento: 'https://outlook.office.com/bookwithme/user/2422add28b9d4512ae14e3069ef903d8@melies.com.br?anonymous&ep=pcard' },
        
        { name: 'Carlos Avelino', disciplina: '2D', link_online: 'https://outlook.office.com/bookwithme/user/8c2786a509114040832e72864d8b005a%40melies.com.br/meetingtype/d1beb510-8afd-4b98-b1b8-a84703595e67?bookingcode=bd3ce562-2cca-4831-a580-c2315118487e&anonymous&isanonymous=true', link_presencial: '' },
        
        { name: 'Daniel Inhauser', disciplina: 'Look Dev, Produção', link_online: 'https://shre.ink/ajD4', link_presencial: 'https://shre.ink/ajDJ', link_atendimento: '' },

         { name: 'Daniel Masuda', disciplina: 'VFX, Efeitos e Dinâmica', link_online: ' https://outlook.office.com/bookwithme/user/c746384b29bd409fbce836e70e9ea549@melies.com.br?anonymous&ep=pcard ', link_presencial: '' },
        
        { name: 'Daniela Rossi', disciplina: 'Animação 3D', link_online: '', link_presencial: '', link_atendimento: 'https://outlook.office.com/bookwithme/user/1d0b367100764a6cbcad4ac155ce962e@melies.com.br?anonymous&ep=pcard' },
        
        { name: 'Danilo Aguiar', disciplina: 'Game Design, Fund. 3D, Proj. Integ. ', link_online: 'https://encurtador.com.br/beCHP', link_presencial: '', link_atendimento: 'https://outlook.office.com/bookwithme/user/45a32573c40e49b2989949a0823ad372@melies.com.br/meetingtype/r6WsKG7R2k2A6hmUHpTXnw2?bookingcode=e51e4ac3-8ec7-42d2-843d-db4bc3904cfb&anonymous&ep=mcard' },
        
        { name: 'Denner Hall', disciplina: 'Sonorização', link_online: 'https://outlook.office.com/bookwithme/user/5640135fd15f403996da9fbf4381d920%40eba1c994-c3c8-4e60-b3d7-aa339e9ed490/meetingtype/a54a26a3-18f2-4eb3-bce3-1ac2297cfa1b?anonymous', link_presencial: 'https://outlook.office.com/bookwithme/user/5640135fd15f403996da9fbf4381d920%40eba1c994-c3c8-4e60-b3d7-aa339e9ed490/meetingtype/08392aea-ba90-4561-8482-66ac086b0dcd?anonymous', link_atendimento: '' },
        
        { name: 'Nicolas Yusuki Uema', disciplina: 'Modelagem 3D', link_online: 'https://outlook.office.com/bookwithme/user/bcbb5144e9394588a1dd3682a0ad5f2b@melies.com.br?anonymous&ep=pcard', link_atendimento: '' },
        
        { name: 'Diego Gozze', 
        disciplina: 'COORDENADOR ANIMAÇÃO', 

        link_online: 'https://outlook-sdf.office.com/bookwithme/user/a61e71dd4e214151b477cda4e22870be%40melies.com.br/meetingtype/39650121-167b-4b11-bb1b-e713df7ab9af?bookingcode=3ee1c876-f385-43c7-946d-b2cd6bb0d110&anonymous&isanonymous=true',

         link_presencial: 'https://outlook.office.com/bookwithme/user/a61e71dd4e214151b477cda4e22870be%40melies.com.br/meetingtype/ff719c38-1c0e-47be-8ab4-5c6a535c3b42?bookingcode=b09760c4-0c3e-4dc0-bff8-4a04a0b2d050&anonymous&isanonymous=true', 
         link_atendimento: '' },
        
        { name: 'Gabriel Pereira',
         disciplina: 'Shader, Assets', 
         link_online: 'https://shre.ink/TkW2', 
         link_presencial: '' },
        
        { name: 'Gabriel Portella', 
        disciplina: 'Edição, Fotografia e Iluminação',
        link_online: 'https://outlook.office.com/bookwithme/user/dfcf8e12bd804c6ebad9804587044b3b%40melies.com.br/meetingtype/E7sgtpi3NEKheBDiL1A4cw2?anonymous', link_presencial: 'https://outlook.office.com/bookwithme/user/dfcf8e12bd804c6ebad9804587044b3b%40melies.com.br/meetingtype/JzeCCtWoTkuTjS9QZaCUZQ2?anonymous', 
        link_atendimento: '' },
        
        { name: 'Gabriel Vinícius',
         disciplina: 'Animação 3D', 
         link_online: 'https://outlook.office.com/bookwithme/user/bb326b4a1957446b9dc5f37a32808b6c@melies.com.br/meetingtype/7IBx0RCtB0y-oqDkdegRmA2?bookingcode=72721a73-a0d3-4e0d-9ea6-41bd036d5700&anonymous&ep=mcard', 
         link_presencial: 'https://outlook.office.com/bookwithme/user/bb326b4a1957446b9dc5f37a32808b6c@melies.com.br/meetingtype/ZrD5lZUzPk-wX8nvN9gz7w2?bookingcode=4133e9f6-7cc3-461a-9c32-9dd789caf555&anonymous&ep=mcard', 
         link_atendimento: '' },
        
        { name: 'Ivan Paz', 
        disciplina: 'Jogos Digitais', 
        link_online: 'https://outlook-sdf.office.com/bookwithme/user/7b39da255cc94371a7b2b6c2d83d0532@melies.com.br/meetingtype/WnlhK2CYc0CoyiMVcwRtrg2?bookingcode=6a59e86f-48b0-4008-996c-35aa3d53a866&anonymous&ep=mcard', 
        link_presencial: '', 
        link_atendimento: '' },
        
        { name: 'Jonatas Serra',
         disciplina: 'Motion, Workflow, Comp. e Pós VFX', 
         link_online: '', 
         link_presencial: '', 
         link_atendimento: ' https://outlook.office365.com/owa/calendar/AtendimentoProfJonatas@melies.com.br/bookings/' },
   
       
        /* { name: 'João Boldrini', 
        disciplina: 'DIRETOR GERAL, Portfólio', 
        link_online: '', 
        link_presencial: '',
        link_atendimento: 'https://outlook.office.com/bookwithme/user/ec3885b4d79544f090373f04c00a89de@melies.com.br?anonymous&ep=pcard' }, */
        
        { name: 'Juliano Caravela', 
        disciplina: 'COORDENADOR PÓS',
         link_online: 'https://outlook.office.com/bookwithme/user/2aeae955b1654d9187db4cac3892f34e@academiamelies.onmicrosoft.com?anonymous&ep=pcard', 
         link_presencial: '', 
         link_atendimento: '' },
        
        { name: 'Kauê Peres',
         disciplina: 'Modelagem 3D', 
         link_online: 'https://outlook.office365.com/owa/calendar/AtendimentoOnlineKau@melies.com.br/bookings/',
          link_presencial: 'https://outlook.office365.com/owa/calendar/AtendimentoPresencialKau@melies.com.br/bookings/', 
          link_atendimento: '' },
       
        { name: 'Lia Fuziy', 
        disciplina: 'Jogos Digitais', 
        link_online: 'https://shre.ink/ajW1', 
        link_presencial: '' },
        
        { name: 'Lika Maekawa',
         disciplina: 'Pintura Digital, Desenho e Fund. 2D',
          link_online: '', 
          link_presencial: '',
          link_atendimento: 'https://outlook.office.com/bookwithme/user/bf1a2ac6d3ca43bebe5299a66d4c78e8@melies.com.br?anonymous&ep=pcard' },
        
        { name: 'Lina Molina', 
        disciplina: 'Direção de Arte, Edição e Pós', 
        link_online: 'https://shre.ink/ajWl', 
        link_presencial: '', 
        link_atendimento: '' },
        
        { name: 'Lucas Silvério',
         disciplina: 'Animação e Rig',
          link_online: 'https://encurtador.com.br/moqM6',
           link_presencial: 'https://outlook.office.com/bookwithme/user/c0e2c48fe7804162bbf7421f682c0d6e@melies.com.br/meetingtype/IvACWUmB5UCM_SXHNksB1A2?bookingcode=920e89ee-b5b0-4a36-9ccb-23ea3bbface5&anonymous&ep=mcard',
            link_atendimento: '' },
        
        { name: 'Rafael Mattos',
         disciplina: 'COORDENADOR JOGOS', 
         link_online: '', link_presencial: '', 
         link_atendimento: 'https://outlook.office.com/bookwithme/user/802db464750a4e438e20c68915260ac7@melies.com.br?anonymous&ep=email&ep=email' },
        
        { name: 'Ricardo Olímpio', 
        disciplina: 'COORDENADOR DESIGN',
         link_online: 'https://encurtador.com.br/noV34', 
         link_presencial: '',
          link_atendimento: '' },
        
        // { name: '',disciplina: '', link_online: '', link_presencial:'' },
       
        { name: 'William Mur',
         disciplina: 'Desenho, Des. Cenário/Personagem', 
         link_online: 'https://shre.ink/Tkdb', 
         link_presencial: '', 
         link_atendimento: '' },
        
        { name: '(Zoê) JoVy Chou',
         disciplina: 'Desenho e Pré-Produção', 
         link_online: 'https://outlook.office.com/bookwithme/user/f5ec8e4fe8294052b47d8f91e37dbcb2%40melies.com.br/meetingtype/424b388f-6398-438a-b96c-98367a17db60?bookingcode=eb99d984-0d83-409b-88d7-7badc0abc4cf&anonymous&isanonymous=true', 
         link_presencial: '', 
         link_atendimento: '' },

    ])

    return (
        <AgendaWrapper>
            <Header />
            <Banner button="Booking" img={IconFour} />
            <div className="containerItems">
                <div className="containerNotas">




                    <table className="tabela" width="100%" >
                        <tr>
                            <th className="semestre">PROFESSOR</th>
                            <th className="">DISCIPLINAS</th>
                            <th className="media">AGENDAMENTO</th>
                            
                        </tr>
                        {
                            booking.map((i, index) => {
                                return (
                                    <tr>
                                        <td className="materia">{i.name}</td>
                                        <td className="materia">{i.disciplina}</td>
                                        <td className="nota" align="center">
                                            <div className="containernota">
                                            {
                                                i.link_online ?
                                                    <a className="agenda" href={i?.link_online} target="_blank">ONLINE</a>
                                                    : ''
                                                        
                                                      

                                                        
                                                      
                                            }
                                            {
                                                i.link_presencial ?
                                                <a className="agenda" href={i?.link_presencial} target="_blank">PRESENCIAL</a>
                                                    : ''
                                            }
                                            {
                                                i.link_atendimento ?
                                                <a className="agenda" href={i?.link_atendimento} target="_blank">ATENDIMENTO</a>
                                                : ''
                                            }
                                            </div>
                                        </td>
                                        

                                    </tr>

                                )
                            })
                        }




                    </table>







                </div>




            </div>

        </AgendaWrapper>
    )

}
export default Agenda
