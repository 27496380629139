import React from "react";
import styled from "styled-components";

const NotasWrapper = styled.div`
    width: 100%;
    height: 100%;   
    max-height: 470px;
    min-height: 272px;
    h1{
        color: #fff
    }

table{
    margin: 5px;
    height: 100%;
   
}
tr{
    color: #fff
}

th{
    padding: 25px;    
    background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    
   
}


.containerNotas{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.materia{
    color: #000; 
    background-color: #F49519;
    padding: 10px 15px;
}
.nota{
    color: #F49519;
       
    background-color: #302114;
}
.semestre{
 border-radius: 20px 0px 0px 0px;
}

.media{
 border-radius: 0px 20px 0px 0px;
}
.footerFalta{
    border-radius: 0px 0px 20px 0px;
}
.meio{
    border-radius: 0px 0px 0px 20px;
}
 
@media (max-width: 1800px){
    max-height: 272px;

}
@media (max-width: 599px){   
    h1{
        font-size: 18px;
    }
}


`

function NotasComponent({ arr = [], nomeTurma = "" }) {

    const calcularMedia = (notas) => {
        console.log(arr)
        // Agrupar as notas por disciplina
        const notasPorDisciplina = {};
        notas.forEach(nota => {
            if (!notasPorDisciplina[nota.cd_disciplina]) {
                notasPorDisciplina[nota.cd_disciplina] = [];
            }
            notasPorDisciplina[nota.cd_disciplina].push(nota);
        });

        // Calcular a média para cada disciplina
        const mediasPorDisciplina = Object.values(notasPorDisciplina).map(notasDisciplina => {
            // Ordenar as notas em ordem decrescente
            const sortedNotas = notasDisciplina.sort((a, b) => b.vl_nota - a.vl_nota);

            // Aplicar as regras de cálculo
            let media = 0;
            if (sortedNotas.length === 1) {
                media = sortedNotas[0].vl_nota;
            } else if (sortedNotas.length === 2 && sortedNotas[1]?.vl_nota === 0) {
                media = sortedNotas[0].vl_nota;
            } else if (sortedNotas.length === 2 && sortedNotas[1]?.vl_nota !== 0) {
                media = (sortedNotas[0].vl_nota + sortedNotas[1].vl_nota) / 2;
            } else if (sortedNotas.length >= 3) {
                media = (sortedNotas[0].vl_nota + sortedNotas[1].vl_nota) / 2;
            }

            return parseFloat(media.toFixed(2));
        });

        // Calcular a média final considerando as médias por disciplina
        let mediaFinal = 0;
        if (mediasPorDisciplina.length > 0) {
            mediaFinal = mediasPorDisciplina.reduce((total, media) => total + media, 0) / mediasPorDisciplina.length;
        }

        return mediaFinal;
    };
    const planosAvaliacaoProcessados = {};

    return (
        <NotasWrapper>
            <h1>{nomeTurma}</h1>
            <div className="containerNotas">
                {
                    arr.map((alunoNotas, index) => {
                        if (alunoNotas.length > 0) {
                            const nmPlanoAvaliacao = alunoNotas[0]?.nm_plano_avaliacao;

                            if (!planosAvaliacaoProcessados[nmPlanoAvaliacao]) {
                                planosAvaliacaoProcessados[nmPlanoAvaliacao] = true;
                                return (
                                    <table className="tabela" width="400" key={index}>
                                        <thead>
                                            <tr>
                                                <th className="semestre">{alunoNotas[0]?.nm_plano_avaliacao}</th>
                                                <th className="media">Média</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {alunoNotas.map((nota, notaIndex) => {
                                                let cont = notaIndex > 0 ? notaIndex - 1 : 0;
                                                let isFirstNote = notaIndex === 0 || nota.nm_disciplina !== alunoNotas[cont].nm_disciplina;

                                                if (isFirstNote) {
                                                    let notaMedia = calcularMedia(alunoNotas.filter(n => n.cd_disciplina === nota.cd_disciplina));
                                                    let notafinal = Math.round(notaMedia * 2) / 2;

                                                    return (
                                                        <tr key={notaIndex}>
                                                            <td className="materia">{nota.nm_disciplina}</td>
                                                            <td className="nota" align="center">{notafinal}</td>
                                                        </tr>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className="meio"></th>
                                                <th className="footerFalta"></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                );
                            }
                        }
                        return null;
                    })
                }
            </div>
        </NotasWrapper>
    );
}


export default NotasComponent;
