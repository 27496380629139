import React, { useState, useEffect } from "react"
import axios from "axios";
import IconButton, { IconButtonHelp } from "../../components/iconsButton";
import styled from "styled-components";
import IconOne from "../../fotos/icones/brancos/brancos-01.png"
import IcoTwo from "../../fotos/icones/brancos/brancos-02.png"
import IconThree from "../../fotos/icones/brancos/brancos-03.png"
import IconFour from "../../fotos/icones/brancos/brancos-04.png"
import IconFive from "../../fotos/icones/brancos/brancos-05.png"
import IconSix from "../../fotos/icones/brancos/brancos-06.png"
import IconSeven from "../../fotos/icones/brancos/brancos-07.png"
import Banner from "../../components/banner";
import Header from "../../components/component";

const PageWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
   // height: 100%;
    width: 100%;
    padding: 0px 30px;
    margin-top: 72px;

    .containerItems{       
        display: flex;
        align-items: center;        
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        height: 100%;    
      
    }
    a{
        text-decoration: none;
        margin: 5px;
      
    }
    
    .newButtom{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 246px;
        width: 100%;
    }
    .msg {
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding:  10px;
    }
    .msg span{
        color: #fff;
        font-family: 'Metropolis', sans-serif;
        font-size: 20px;
    }
 ::-webkit-scrollbar{
     width: 10px;   
 }
 ::-webkit-scrollbar-track{
     background-color: #000;
 }
  ::-webkit-scrollbar-thumb{
     background-color: #000;
     border-radius: 20px;
}
 ::-webkit-scrollbar-thumb:hover{
     background-color: #000;
}
.newbutton{
    max-width: 243px;
    width: 100%;
}
@media (max-width: 1024px){
  .containerItems{  
      max-height: 582px;
  }

}
@media (max-width: 959px){
    align-items: center;
  .containerItems{
    justify-content: center;  
      
  }

}
@media (max-width: 850px){
    .containerItems{  
        max-height: 979px;
    }
  
  }
@media (max-width: 768px){   
    align-items: center; 
     max-width: 574px;
        
  .containerItems{
    justify-content: flex-start;
    width: 98%;
    //margin-left: 40px;
      
  }

}
@media (max-width: 699px){
    .containerItems{  
        max-height: unset;
    }
  
  }
@media (max-width: 599px){       
    max-width: 420px;
     padding: 0px 0px;
     .containerItems{
        margin-left: 25px;
        //max-height: 250px;
        //min-height: 200px;
     }

}
@media (max-width: 405px){  
    max-width: 317px;
}

`


function PortalAluno() {
    const [nomeAluno, setName] = useState("")
    const [interesse, setIteresse] = useState(null)
    const token = localStorage.getItem("token")
    const [loading, setLoading] = useState(false)
    const pegarAluno = async () => {

        await axios.get(`${process.env.REACT_APP_API}/usuario `, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { name, interesse } = response.data
            setIteresse(interesse)
            let name_abrv = name.split(" ")

            let primeiro_nome = name_abrv[0];
            let segund_nome = name_abrv.length > 1 ? name_abrv[name_abrv.length - 1] : ''
            let nomeApre = primeiro_nome + ' ' + segund_nome
            await setName(nomeApre)
            await carregar()
        }).catch(error => {
            const { response = {} } = error
            const { status = 0 } = response
            if (status == 401) {
                console.log("erro", error)
                window.location.href = "/autenticacao";
            }
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }

    const carregar = async () => {
        await setLoading(true)
        setTimeout(async () => {
            await setLoading(false)
        }, 5000);

    }

    useEffect(() => {
        pegarAluno()

    }, [])
    return (
        <>
            <PageWrapper style={{padding: interesse != null ? '200px 0px 60px 0px' : '10px 0px 40px 0px'}}>
                <Header loadingSet={false} visibleBack={false} />
                <Banner text={nomeAluno} />
                {/* <div className="msg">
                    <span>Rematrícula liberada. Caso você não tenha essa opção,<br/> fale conosco em contato@melies.com.br</span>

                </div> */}
                <div className="containerItems">
                    {
                        interesse != null &&
                        <IconButton img={IconSix} text="Rematricula" link={`http://admmelies.com.br/AdmMelies/rematricula/rematricula_contrato.php?cd_cliente=${interesse.cd_cliente}&cd_curso=${interesse.cd_curso}`} targetBlank={true} />
                    }
                    <IconButton text="Atendimento Professor" link="/agenda-professor" />
                    <IconButton  text="Plantão de Dúvidas "targetBlank={true} link="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fteam%2F19%3Ac268aa4fb8354348ae001af93896af9b%40thread.tacv2%2Fconversations%3FgroupId%3Ddaa041e8-68c7-434d-b5b1-3fd2792d1dd5%26tenantId%3Deba1c994-c3c8-4e60-b3d7-aa339e9ed490&type=team&deeplinkId=7b323fb0-d192-4725-9741-955510ef7941&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true" />
                    <IconButton  text="Suporte técnico " link="https://teams.microsoft.com/l/chat/0/0?users=suporte@melies.com.br" />
                    <IconButton img={IconOne} text="Atividade Complementar" link="/atividade-complementar" />
                   
                    <IconButton img={IcoTwo} text="Boletim" link="/notas-frequencia" />
                    <IconButton img={IconSix} text="Biblioteca" link="https://melies.odilo.us/" targetBlank={true} />
                    <IconButton img={IconSeven} text="Boletos" link="/boleto" />
                    <IconButton text="CPA"  link="/cpa"/>
                    <IconButton img={IconSix} text="Software" link="https://melies.com/softwares" targetBlank={true} />
                    <IconButton  text="Links importantes" link="/links-importantes"  />
                    <IconButton img={IconFive} text="Cronograma" link="/cronograma" />
                    {/* <IconButtonHelp img={IconThree} text="Ajuda" link="/ajuda" className="newbutton" /> */}
                    <IconButton img={IconFive} text="Calendário Acadêmico" link="https://melies.com/calendario/" targetBlank={true} />
                    {/* <IconButton  text="Trocar de Senha" link="/mudar-senha" /> */}

                </div>
                

            </PageWrapper>

        </>
    );

};

export default PortalAluno
