import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import axios from "axios";
import CancelIcon from '@mui/icons-material/Cancel';

export default function BoxForm(props) {
    const { setLoading, formOpe, alertMsg, edit } = props
    const [data, setData] = useState({ fabricante: '', nome: '', versao: '', obs: '', ativo: true, id_software: 0})
    const [license, setLicense] = useState([])   
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const { fabricante, nome, versao, obs, ativo,id_software } = data
    const onchange = async (e) => {
        let { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }
 

    const save = async () =>{
        await setLoading(true)
        if (edit != '') {
          return  await editSoft()
        }
      
        await axios.post(`${process.env.REACT_APP_API}/software`,data, {
            headers: {                
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data } = response
            formOpe()
            await setData(data)
            await setLoading(false)
            await alertMsg('Software cadastrado com sucesso','success',1)
            
        }).catch(async error => {
            setLoading(true)
            await alertMsg('Houver um error ao cadastrar o software', 'error',1)
            console.log("erro", error)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })
           
        })

    }
    const editSoft = async () =>{
        await axios.patch(`${process.env.REACT_APP_API}/software/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data } = response
            formOpe()
            await setData(data)
            await setLoading(false)
            await alertMsg('Software editado com sucesso', 'success', 1)

        }).catch(async error => {
            setLoading(true)
            await alertMsg('Houver um error ao cadastrar o software', 'error', 1)
            console.log("erro", error)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })

    }
   
    const getlicense = async () => {
        await axios.get(`${process.env.REACT_APP_API}/license`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {} } = response.data
            await setLicense(data)


        }).catch(error => {
            console.log("erro", error)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }

    useEffect(() => {
        
        if (typeof edit != 'undefined'){
            setData(edit)
        }
        getlicense()
  
    }, [])


    return (
        <div style={styles.container} >
            <div style={{textAlign: 'center'}}>
                <h3 style={{ color: '#fff' }}>Adicionar um novo Software</h3>
            </div>
            <CancelIcon style={styles.cancel} onClick={formOpe}/>
            <div style={styles.contet}>

                <div style={styles.containerItems}>
                    {/* <div style={styles.item}>                       
                        <TextField id="standard-basic" name="fabricante" label="Fabricante" value={`${fabricante}`} variant="standard" onChange={ onchange} />
                        
                    </div> */}
                    <div style={styles.itemC}>                       
                        <TextField style={{ maxWidth: 400, width: '100%' }} id="standard-basic" label="Nome" name="nome" value={`${nome}`} variant="standard" onChange={ onchange} />
                    </div>
                </div>
                <div style={styles.containerItems}>
                    <div style={styles.itemC}>                       
                        <TextField style={{maxWidth: 400, width: '100%'}} id="standard-basic" label="Versão" name="versao" value={`${versao}`} variant="standard" onChange={ onchange} />
                    </div>                   
                 
                </div>
                <div style={styles.containerItems}>
                    <div style={styles.itemC}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{ maxWidth: 400, width: '100%' }}>
                            <InputLabel id="demo-simple-select-standard-label">Software</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={id_software}
                                onChange={onchange}
                                label="Age"
                                name="id_software"
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {
                                    license.map((item) => {
                                        return(
                                            <MenuItem value={item.id}>{item.empresa}</MenuItem>
                                        )
                                    })
                                }
                               
                           

                            </Select>
                        </FormControl>

                    </div>
                </div>
                <div style={styles.containerItems}>
                <div style={styles.itemC}>
                    <TextField
                        id="standard-multiline-static"
                        label="Campo para obs"
                        onChange={onchange}
                        name="obs"
                        value={`${obs}`}
                        multiline
                        style={styles.textarea}
                        rows={4}
                        //defaultValue="Default Value"
                        variant="standard"
                    />
                </div>
                </div>

                {/* <div style={styles.containerItemsC}>
                    <div style={styles.itemCH}>
                        <Switch {...label} value={ativo} name="ativo" onClick={() => ative(ativo)}/>
                    </div>
                </div> */}

                <div style={styles.containerItems}>
                    <div style={styles.item} >                    
                        <Button variant="contained" color="success" onClick={() => save()}>
                            Gravar
                        </Button>
                    </div>

                </div>




            </div>
       

        </div>
    )


}
const styles = {
    container: {
        background: '#333333',
        position: 'absolute',
        top: '4px',
        left: '470px',
        borderRadius: 8,
        width: 524,
        padding: 5
    },
    content:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    containerItems:{
        padding: 5,
        display: 'flex',        
        justifyContent: 'space-around',
        width: '100%'
    },
    input: {        
        borderRadius: 5,
        padding: 5,
        
    },
    item:{
        display: 'flex',
        flexDirection: 'column',        
        alignItems: 'center',
        justifyContent: 'center',       
        
    },
    itemC: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'initial',
        width: '88%'

    },
    itemCH: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'initial',      

    },
    containerItemsC: {
        padding: 5,
        display: 'flex',
        justifyContent: 'initial',
        width: '100%'
    },
    textarea: {
        margin: 10,
        borderRadius: 5,
        width: '88%',            
    },
    cancel: {
        position: 'absolute',
        top: 5,
        right: 7,
        color: '#fff',
        cursor: 'pointer'
    }


}