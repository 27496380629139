import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, useHistory, Switch } from 'react-router-dom'
import PortalAluno from "./pages/PortalAluno";
import License from "./pages/LicensaSoft";
import Notas from "./pages/Notas"
import Aviso from "./pages/Aviso";
import Autentica from "./pages/Autenticacao";
import Boleto from "./pages/Boleto";
import Ajuda from "./pages/Ajuda";
import Biblioteca from "./pages/Biblioteca";
import Cronogrma from "./pages/Cronograma";
import PrivateRoute from "./privateRoute";
import Frequencia from "./pages/Frequencia"
import AtivComple from "./pages/Atividades-complementares"
import NotasEscolha from "./pages/Notas/notas";
//import RedefinirSenha from "./pages/Redefinir-senha";
import Agenda from "./pages/Agenda-professor";
import Links from  "./pages/links-importantes"
import CPA from "./pages/cpa";
import RedefinirSenha from "./pages/Alterar-senha";



function Rotas() {
    return (
        <BrowserRouter>
            <Switch>
           
                    <PrivateRoute path="/" exact component={PortalAluno} />
                    <PrivateRoute path="/notas" exact component={Notas} />
                    <PrivateRoute path="/notas-frequencia" exact component={NotasEscolha} />
                    <PrivateRoute path="/aviso" exact component={Aviso} />
                    <Route path="/licensa-software" component={License} />
                    <Route path="/autenticacao" component={Autentica} />
                    {/* <Route path="/redefinir-senha" component={RedefinirSenha} /> */}
                    <PrivateRoute path="/boleto" component={Boleto} />
                    <PrivateRoute path="/ajuda" component={Ajuda} />
                    <PrivateRoute path="/biblioteca" component={Biblioteca} />
                    <PrivateRoute path="/frequencia" component={Frequencia} />
                    <PrivateRoute path="/cronograma" component={Cronogrma} />
                    <PrivateRoute path="/atividade-complementar" component={AtivComple} />
                    <PrivateRoute path="/agenda-professor" component={Agenda} />
                    <PrivateRoute path="/links-importantes" component={Links} />
                    <PrivateRoute path="/cpa" component={CPA} />
                    <PrivateRoute path="/mudar-senha" component={RedefinirSenha} />
              

            </Switch>
        </BrowserRouter>
    )


}

export default Rotas;

