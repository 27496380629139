import React, { useState, useEffect } from "react";
import Header from "../../components/component";
import Banner from "../../components/banner";
import IconSeven from "../../fotos/icones/brancos/brancos-07.png"
import BoletoComponet from "../../components/boleto";
import styled from "styled-components";
import axios from "axios";
const BoletoWrapper = styled.div`

    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;

    .containerItems{       
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }
    
@media (max-width: 1024px){
  .containerItems{  
      max-height: 389px;
      width: 96%;
  }

}
@media (max-width: 979px){
    width: 100%;
    max-width: 685px;

}
@media (max-width: 959px){
    align-items: center;

}
@media (max-width: 768px){   
    align-items: center; 
     max-width: 574px;
        
  .containerItems{
    justify-content: flex-start;
    width: 98%;
    //margin-left: 40px;
      
  }

}
@media (max-width: 599px){       
    max-width: 420px;
     padding: 0px 0px;
     .containerItems{       
        max-height: 250px;
        min-height: 200px;        
        width: 91%;
       

     }
     
    

}
@media (max-width: 405px){  
    margin-left: 43px;
    width: 76%;
    span{
        font-size: 9px;
    }
}
  
`
function Boleto(){
    const [boleto, setBoleto] = useState([])
    const token = localStorage.getItem("token") 
    const pegarBoleto = async () => {
                
        await axios.get(`${process.env.REACT_APP_API}/boleto `,  {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {}} = response.data
            await setBoleto(data)
        }).catch(error => {
            const { response = {} } = error
            const { status = 0 } = response
            if (status == 401) {
                console.log("erro", error)
                window.location.href = "/autenticacao";
            }
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }


    useEffect(() => {
        pegarBoleto()

    }, [])

    return(
        <BoletoWrapper>
            <Header />
            <Banner button="Boletos" img={IconSeven} />
            <div className="containerItems">
                <BoletoComponet boleto={boleto}/>
               


            </div>
        </BoletoWrapper>
    )

}

export default Boleto;