
import { display, height } from "@mui/system";
import CircularIndeterminate from "../../components/loading";
import { useEffect, useState } from 'react'
import BoxForm from "../../components/boxForm";

function License(props) {


    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const idurl = urlParams.get("id")

        if (idurl != `${process.env.REACT_APP_AUTH}`) {
            // return window.location.href = "https://admmelies.com.br/AdmMelies/";
        }
      
    }, [])

    return (
        <>
            <div style={styles.container}>
                <h1 style={styles.containerText}>Gerenciador de software e Licenças  Méliès</h1>
                <div style={{ width: '100%', height: '100%' }}>
                    <BoxForm setLoading={setLoading.bind(this)} />
                </div>

            </div>
        </>
    );
}
const styles = {
    container: {

        width: '100%',
        display: 'flex',
        maxWidth: 1403,
        flexDirection: 'column',
        padding: 28,
        background: '#fff',
        border: '1px solid rgb(0,0,0,0.5)',
        borderRadius: 13,
        position: 'relative',
        boxShadow: 'black 11px 7px 6px 9px',
        height: 585

    },
    containerBackdrop: {
        display: 'flex',
        width: '100%',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgb(0,0,0,0.6)',
        height: '100%',
        top: 0
    },
    containerText: {
        fontFamily: "sans-serif",
        textAlign: 'center',
        marginTop: 71,
        marginBlockEnd: 0

    }
}


export default License;
