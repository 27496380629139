import React, { useState } from "react";
import styled from "styled-components";
import Moment from 'react-moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
const CronoWrapper = styled.div`
    width: 100%;
    height: 100%;      
    max-height: 455px;
    min-height: 272px;
    overflow-y: auto;
    h2{
        color: #fff;
        
    }
    h1{
        color: #fff
    }

table{
    margin: 5px;
    height: 100%;
}
tr{
    color: #fff
}

th{
    padding: 25px;    
    background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    
   
}


.containerNotas{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
}
.materia{
    color: #F49519;
    background-color: #302114;
    padding: 10px 15px;
}
.nota{
    background-color: #F49519;
    color: #000;    
}
.semestre{
 border-radius: 20px 0px 0px 0px;
}
.media{
 border-radius: 0px 20px 0px 0px;
}

.containerItemTurma{
    display: flex;
    flex-direction: column;
   
}
.contentOpen{
    display: flex;
    align-items: center;
    cursor:pointer;
}
svg{
    color: #F49519;
    margin-left: 5px;

}

@media (max-width: 1800px){
    max-height: 272px;

}





`

function CronogComponent({ arr = [], nomeTurma = "" }) {
    const [openItem, setOpen] = useState("")
    const open = (value) =>{
        if(openItem != ''){
            setOpen("")
        }else{
            setOpen(value)

        }

    }
    const days = (day) =>{
        
        switch (day) {
            case 0:
                return "Domingo"                
                break;
            case 1:
                return "Segunda-feira"
                break;
            case 2:
                return "Terça-feira"
                break;
            case 3:
                return "Quarta-feira"
                break;
            case 4:
                return "Quinta-feira"
                break;
            case 5:
                return "Sexta-feira"
                break;
            case 6:
                return "Sabado"
                break;
        
            default:
                break;
        }
    }
    

    return (
        <CronoWrapper>
            
            <h1>{nomeTurma}</h1>
            <div className="containerNotas">
                {
                    arr.map((i, index) => {
                        if(i.length == 0){
                            return
                        }

                        return (
                            <div className="containerItemTurma">
                                <div className="contentOpen" onClick={() => open(i[0].nome_turma)}>
                                <h2 >{i[0].nome_turma}</h2>
                                    {openItem == i[0].nome_turma ? <ArrowDropUpIcon /> :  <ArrowDropDownIcon />} 
                                </div>
                                <div >
                                
                                      {
                                        openItem == i[0].nome_turma &&
                                        <table  >
                                            <tr>
                                                <th className="semestre">Professor(a)</th>
                                                <th className=""> Matéria </th>
                                                <th className=""> Dia</th>
                                                <th className=""> Data</th>
                                                <th className="media"> Observação</th>




                                            </tr>
                                            {i.map((element) => {
                                                element.dt_aula = element.dt_aula.split("T")[0]
                                                let date = new Date(element.dt_aula);
                                                
                                              

                                                return (
                                                    <>



                                                        <tr>

                                                            <td className="materia" align="center">{element.nm_usuario}</td>
                                                            <td className="materia" align="center">{element.nm_disciplina}</td>
                                                            <td className="materia" align="center">{days(date.getDay()+1)}</td>
                                                            <td className="materia" align="center">
                                                                <Moment format="DD/MM/YYYY">
                                                                    {element.dt_aula}

                                                                </Moment>
                                                            </td>
                                                            <td className="materia" align="center">{element.ds_observacao}</td>
                                                        </tr>


                                                    </>
                                                )
                                            })
                                            }



                                        </table>
                                      }                                   

                                </div>
                            </div>


                        )
                    })
                }


            </div>
        </CronoWrapper >
    )
}

export default CronogComponent