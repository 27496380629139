import React from "react";
import styled from "styled-components";

const NotasFreqComponentWrapper = styled.div`
width: 100%;
height: 100%;    
overflow-y: auto;
max-height: 470px;
min-height: 272px;
display: flex;
justify-content: space-evenly;
align-items: center;

.botaoDireita{
     background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    width: 100%;    
    padding: 15px;
    height: 50px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.botaoEsquerdo{
    background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    width: 100%;
    padding: 15px;
    height: 50px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

}
span{
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}
a{
    text-decoration: none;
    
}
@media (max-width: 599px){   
    flex-direction: column;
    .botaoDireita{
        max-width: 222px;
    }
    .botaoEsquerdo{
        max-width: 222px;
    }
}


`



function notasFrequenciaComponente() {

    return (
        <NotasFreqComponentWrapper>
            <a className="botaoDireita" href="/notas">
                <span>Notas</span>
            </a>
            <a className="botaoEsquerdo" href="/frequencia">
                <span>Frequência</span>

            </a>


        </NotasFreqComponentWrapper>
    )


}

export default notasFrequenciaComponente