import React, { useState, useEffect } from "react";
import Header from "../../components/component";
import Banner from "../../components/banner";
import IconFour from "../../fotos/icones/brancos/brancos-04.png"
import CronogComponent from "../../components/cronograma";
import styled from "styled-components";
import axios from "axios";
const CronogramaWrapper = styled.div`

    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;
     overflow: auto;

    .containerItems{       
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }
    
@media (max-width: 1024px){
  .containerItems{  
      max-height: 389px;
      width: 96%;
  }

}
@media (max-width: 979px){
    width: 100%;
    max-width: 685px;

}
@media (max-width: 959px){
    align-items: center;
  .containerItems{
    justify-content: center;  
      
  }

}
@media (max-width: 768px){   
    align-items: center; 
     max-width: 574px;
        
  .containerItems{
    justify-content: flex-start;
    width: 98%;
    //margin-left: 40px;
      
  }

}
@media (max-width: 599px){       
    max-width: 420px;
     padding: 0px 0px;
     .containerItems{
        margin-left: 25px;
        max-height: 250px;
        min-height: 200px;
     }
     
    

}
@media (max-width: 405px){  
    max-width: 317px;
}
  
`
function Cronogrma() {
    const [cronograma, setCrono] = useState([])
    const [nomeCurso, setNome] = useState("")
    const [valid, setVal] = useState(false)
    const token = localStorage.getItem("token")
    const pegarCronograma = async () => {

        await axios.get(`${process.env.REACT_APP_API}/cronograma `, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {} } = response.data
          
            await setCrono(data)
           
        }).catch(error => {
            const { response = {} } = error
            const { status = 0 } = response
            if (status == 401) {
                console.log("erro", error)
                window.location.href = "/autenticacao";
            }
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }


    useEffect(() => {
   
            pegarCronograma()

        
        
    }, [])
    

    return (
        <CronogramaWrapper>
            <Header />
            <Banner button="Cronograma" img={IconFour} />
            <div className="containerItems">
                <CronogComponent arr={cronograma} nomeTurma={nomeCurso} /> 



            </div>
        </CronogramaWrapper>
    )

}

export default Cronogrma;