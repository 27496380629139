import React, { useState, useEffect } from "react"
import styled from "styled-components";
import axios from "axios";
import IconThree from "../../fotos/icones/brancos/brancos-03.png"
import AjudaComponente from "../../components/ajuda/ajuda";
import Banner from "../../components/banner";
import Header from "../../components/component";
import IconFour from "../../fotos/icones/brancos/brancos-05.png"


const AgendaWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;
.containerItems{  
        max-width: 981px;     
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }
       h1{
        color: #fff
    }




.containernota{
    width: 100%;
    display: flex;
}
 
@media (max-width: 1800px){
    max-height: 272px;

}
@media (max-width: 599px){   
    h1{
        font-size: 18px;
    }
}

.containerLink{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.containerLink a, span{
    color: #fff;
    margin: 1px 0px 0px 5px;
    font-size: 18px;
    font-weight: 600;
    padding: 7PX;

}
.itemLink{
    display: flex;
    align-items: center;
}
.linkTitle{
    color: #fff;
    margin: 5px 0px 20px 14px;
    font-size: 22px;
    font-weight: 600;
}
`

function Links() {
  

    return (
        <AgendaWrapper>
            <Header />
            <Banner button="Booking" img={IconFour} />
            <div className="containerLink">
                    <h3 className="linkTitle">Links Importantes:</h3>
                    <div className="itemLink">
                        <span>Dicas para montar seu portfólio:  </span> 
                        <a style={{color: 'rgb(244, 149, 25)'}} href="https://www.instagram.com/stories/highlights/18382169113048127/" target="_blank">Link</a>
                    </div>
                    <div className="itemLink">
                        <span>Lista de Produtoras: </span> <a 
                        style={{color: 'rgb(244, 149, 25)'}} href="https://academiamelies.sharepoint.com/:x:/r/sites/DAO-AVISOSGERAIS/Documentos%20Compartilhados/General/Produtoras/ListaAtualizadadeProdutoras%202023.xlsx?d=wdbfc384d4d894bd48500b2c69cb9ea61&csf=1&web=1&e=iikkwH" target="_blank"> Link</a>

                    </div>
                    <div className="itemLink">
                        <span>Enviar seu material para o Marketing da Méliès: </span> <p 
                        style={{color: 'rgb(244, 149, 25)'}}>melies.mkt@melies.com.br</p>
                    </div>
                <div className="itemLink">
                        <span>Envie suas dúvidas, críticas e sugestões para a Ouvidoria: </span> <a 
                        style={{color: 'rgb(244, 149, 25)'}} href="ouvidoria@melies.com.br">ouvidoria@melies.com.br</a>
                    </div>
                </div>

        </AgendaWrapper>
    )

}
export default Links
