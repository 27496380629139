import Dropzone from "react-dropzone"
import { useState } from "react";
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

export const CustomDropzone = (props) => {


    const [loading, setLoading] = useState(false)

    const {
        callback = () => { },
        bgImage = null,
        bgImageStyle = {},
        propsDropzone = {}
    } = props;

    const onDropFiles = async (files) => {
        const uploadedFiles = files.map(file => ({
            file,
            id: getRandomInt(1, 999),
            name: file.name,
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null
        }))

        uploadedFiles.forEach(processUpload)
    }


    const processUpload = async (uploadedFile) => {
        setLoading(true)
        let filePreview = uploadedFile.preview;
        let fileName = uploadedFile.name;
        const formData = new FormData();
        formData.append('file', uploadedFile?.file, encodeURIComponent(uploadedFile?.name));
        try {

            const token = localStorage.getItem("token")
            const response = await axios.post(`${process.env.REACT_APP_API}/arquivo-atividades-complementar`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST, GET',
                    'Access-Control-Allow-Headers': '*',
                },
            })

            const { data = {}, status } = response;
            const { fileId } = data
            let file = {
                status,
                fileId,
                filePreview,
                fileName
            };

            if (status === 201) {
                callback(file);
            } else {
                alert('Tivemos um problema ao fazer upload do arquivo.');
            }
        } catch (error) {
            console.log(error)
            alert('Tivemos um problema ao fazer upload do arquivo.');
        } finally {
            setLoading(false)
        }
    };

    return (
        <>
            <div style={{ display: 'flex', gap: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Backdrop open={loading}>
                    <CircularProgress />
                </Backdrop>
                <Dropzone
                    accept={{ 'image/jpeg': ['.jpeg', '.JPEG', '.jpg', '.JPG'], 'image/png': ['.png', '.PNG'], 'application/pdf': ['.pdf'] }}
                    onDrop={onDropFiles}
                >
                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                        <div {...getRootProps()}
                            style={{
                                ...styles.dropZoneContainer,
                                border: `1px solid rgb(244, 149, 25)`,
                                // backgroundColor: isDragActive && !isDragReject ? 'lightgray' : isDragReject ? '#ff000042' : 'lightgray',
                                ...(bgImage ? { ...bgImageStyle, border: 'none' } : {}),
                                ...propsDropzone.style
                            }}
                        >
                            <input {...getInputProps()} />
                            {!bgImage &&
                                <div style={{ display: 'flex', fontSize: 12, width: '100%', padding: '10px', }}>
                                    <p style={{ textAlign: 'center', width: '100%', }}>
                                        {props.txt || 'Clique ou arraste aqui seus arquivos para upload'}
                                    </p>
                                </div>
                            }
                        </div>
                    )}
                </Dropzone>
            </div>
        </>
    )
}

// export const manageFiles = async (file) => {

//     const fileObject = {
//         file,
//         id: getRandomInt(1, 999),
//         name: file.name,
//         preview: URL.createObjectURL(file),
//         progress: 0,
//         uploaded: false,
//         error: false,
//         url: null
//     };

//     const response = await uploadFiles(fileObject);
//     return response
// }

// const uploadFiles = async (uploadedFile) => {
//     const formData = new FormData()

//     formData.append('file', uploadedFile.file, encodeURIComponent(uploadedFile.name))

//     const response = await uploadFile({ formData, usuario_id: null, campo: null, tipo: null });
//     const { data = {}, status } = response;
//     const { file = {} } = data;

//     if (status === 201) return file;
//     return null
// }

const styles = {
    dropZoneContainer: {
        display: 'flex',
        // padding: 4,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 2,
        cursor: 'pointer',
        // flex: 1,
    },
    menuIcon: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: 20,
        height: 20,
    },
}