import React, { useState, useEffect } from "react"
import Banner from "../../components/banner";
import IconButton from "../../components/iconsButton";
import styled from "styled-components";
import IcoNota from "../../fotos/icones/brancos/brancos-02.png"
import NotasComponent from "../../components/notas";
import axios from "axios";
import Header from "../../components/component";
const PageWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;

    .containerItems{       
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }
       
@media (max-width: 1024px){
  .containerItems{  
      max-height: 389px;
      width: 96%;
  }

}
@media (max-width: 979px){
    width: 100%;
    max-width: 685px;

}
@media (max-width: 959px){
    align-items: center;
  .containerItems{
    justify-content: center;  
      
  }

}
@media (max-width: 768px){   
    align-items: center; 
     max-width: 574px;
        
  .containerItems{
    justify-content: flex-start;
    width: 98%;
    //margin-left: 40px;
      
  }

}
@media (max-width: 599px){       
    max-width: 420px;
     padding: 0px 0px;
     
     .containerItems{
        margin-left: 7px;
        max-height: 250px;
        min-height: 200px;
     }
     
    

}
@media (max-width: 405px){  
    max-width: 317px;
}
  
`


function Notas() {
    const [notaReal, setReal] = useState([])
    const [nomeTurma, setNome] = useState("")
    const [notarRep, setNota] = useState({cd_cliente:0,cd_curso:0,cd_disciplina:0,cd_index_nota:0,nm_disciplina:"",nm_exercicio:0,nm_plano_avaliacao:"",nome_turma:"",vl_nota:0})
    const token = localStorage.getItem("token") 
    const [loading, setLoading] = useState(false)

    const pegarNotas = async () => {
        let arr = []
        let arrDisc = []
        let arrTeste = []
     
        await axios.get(`${process.env.REACT_APP_API}/notas `, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {}, turmas ={}, disciplina = {}} = response.data
            
            await carregar()  
           await turmas.map((item) => {
           // item.nm_plano_avaliacao = item.nm_plano_avaliacao.replace("_","-")
                let teste = data.filter((i) => {
                    if (i.nm_plano_avaliacao == item.nm_plano_avaliacao){
                        return i;
                    }                    
                })              
                arr.push(teste)              

            });
           
            /*
            await disciplina.map((item) =>{
                let notasRep = data.filter((i)=>{
                    if(i.cd_disciplina == item.cd_disciplina){
                        return i
                    }
                })
                arrDisc.push(notasRep)
            })*/

        
            
            await setNome(turmas[0].nome)
            await setReal(arr) 
            await carregar() 
           


        }).catch(error => {
            const { response = {} } = error
            const { status = 0 } = response
            if (status == 401) {
                console.log("erro", error)
                window.location.href = "/autenticacao";
            }
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }
    function reduzirObjetos(objeto) {
        const objetoReduzido = objeto.reduce((resultado, objeto) => {
            const nome = objeto.nome;
            const valor = objeto.valor;

            if (resultado.hasOwnProperty(nome)) {
                resultado[nome] += valor;
            } else {
                resultado[nome] = valor;
            }

            return resultado;
        }, {});

        return objetoReduzido;
    }

    const carregar = async () => {
        await setLoading(true)
        setTimeout(async () => {
            await setLoading(false)
        }, 5000);

    }


    useEffect(() => {
        pegarNotas()
        

    }, [])

    return (
        <>
            <PageWrapper>
                <Header loadingSet={loading} />
                <Banner button="Notas" img={IcoNota} />
                <div className="containerItems">
                    <NotasComponent arr={notaReal} nomeTurma={nomeTurma} />


                </div>

            </PageWrapper>

        </>
    );

};

export default Notas