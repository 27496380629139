import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularIndeterminate from "../../components/loading";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const AutenticaWrapper = styled.div`
body {
  background-color: #1c1c1c;
  margin:0; 
  padding:0; 
  height:100%;
  font-family: 'Poppins', sans-serif;
}
h3 {
  margin-top: 25px;;
  color: #fff;
  cursor: pointer;
}
   
.btn{
  border: 0px;
  background-color: #680dfe;
  width: 60%;
  text-align: center;
  color: #fff;
  padding: 7px 0;
  cursor: pointer;
  border-radius: 15px;
  margin-top: 30px;
}
.container-bg{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 0;
}
.container-bg .bg{
  width: 25%;
}
.container-bg .bg-esquerda{
  max-height: 499px;
  max-width: 313px;
}
.container-bg .bg-direita{
  max-height: 830px;
  max-width: 511px;
}
 
input, input[type="password"], input[type="text"] {
  border: none;
  padding: 9px 0px;
  max-width: 100%;
  width: 60%;
  font-size: 1em;
  min-width: 200px;
  color: #8a8a8a;
  background-color: #393939;
  padding-left: 15px;
  border-radius: 5px;
  margin-top: 15px;
  font-family: 'Poppins', sans-serif;
}

.center{
  margin:0 auto;
  width: 540px;
  text-align: center;
  height:100%;
  z-index: 1;
  position: relative;

}

.form1{
  margin-top:10%;
  display: block;
  float: left;
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}
.logo{
    margin-top: 0%;
    width: 200px;
}
.containerBackdrop{
    display: flex;
    width: 100vw;
    position: absolute;
    alignItems: center;
    justifyContent: center;
    background: #000;
    height: 100%;
    top: 0;
    opacity: 0.7;
    z-index: 999999999;
    left: 0;
    justify-content: center;
    align-items: center;
}
.css-i4bv87-MuiSvgIcon-root{
    color: #fff;
    position: absolute;
    right: -15px;
    right: 104px;
    top: 21px;
}
.containerItem{
    position: relative;
}
`
function Autentica() {
  const [data, setData] = useState({ email_reset: ''})
  const [alert, setAlert] = useState({ msg: '', openAlert: false, type: 'success' })
  const [loading, setLoading] = useState(false)
  const [resetPassword, setPassword] = useState(false)
  const [seePassword, setPass] = useState(false)

  const change = (e) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value
    })

  }
  const enviar = async (e) => {
    e.preventDefault()
    await setLoading(true)
    let request = 'login';

    if(resetPassword){
      request = 'esqueci-senhar';     
      if(data.email_reset == '' ){
        await setLoading(false)
        await setAlert({ msg: "Por favor digite seu e-mail!", openAlert: true, type: "warning" })
        setTimeout(async () => {
          await setAlert({ msg: "", openAlert: false })
        }, 4000)
        return
      }
    }
    
    await axios.post(`${process.env.REACT_APP_API}/${request} `, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': '*',
      },
    }).then(async response => {
      const { data = {} } = response
      const {auth = false, msg = '', token= ''} = data
      

      if(auth){
        localStorage.setItem("token",token);
        window.location.href = "/";
      
      }
      setPassword(false)
      await setLoading(false)
     
   
    }).catch(async error => {
      await setLoading(false)
      await setAlert({ msg: "Usuario ou senha inválida!", openAlert: true, type: "warning" })
      setTimeout(async () => {
        await setAlert({ msg: "", openAlert: false })
      }, 4000)
      // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

    })
  }
  


  return (
    <>
      <AutenticaWrapper>
        <div className="center">
          <img src="/images/login/logo_degrade_quente_linhas_brancas.png" className="logo" />
          <form>

          <div className="form1">
            {
                ! resetPassword ?
                <>
                    <input type="text" name="login" id="login_site" placeholder="usuario" onChange={change} />
                    <br />
                    <div className="containerItem">
                      <input type={seePassword ? 'text' : 'password'} name="senha" id="login_site" placeholder="Nova senha" autoComplete="off" onChange={change} />
                      {
                        !seePassword ? <Visibility onClick={() => setPass(true)} /> : <VisibilityOff onClick={() => setPass(false)} />
                      }


                    </div>
                    <br />
                </>
                :
                  <>
                    < input type="text" name="email_reset" id="email_reset" autocomplete="off" placeholder="Digite o seu E-mail" onChange={change} />
                    <br />
                  </>

            }           
            <input type="submit" value="Enviar" className="btn" onClick={enviar}/>
            {
               ! resetPassword ?
                <>
                  <br />
                  {/* <h3 onClick={() => setPassword(true)}>Esqueci minha Senha</h3> */}

                </>
                :
                  <input type="submit" value="Voltar" className="btn" onClick={() => setPassword(false)} />
            }
            
          </div>
          </form>

          <div className="container-bg">
            <img className="bg bg-esquerda" alt="fundo-esquerdo" src="/images/login/background/ilustra_esquerda.png" />
            <img className="bg bg-direita" alt="fundo-direito" src="/images/login/background/ilustra_direita.png" />
          </div>
        </div>
        {

          alert.openAlert &&
          <Alert variant="outlined" severity={alert.type}>
            <label>{alert.msg}</label>
          </Alert>

        }
        {
          loading &&
          <div className="containerBackdrop">
            <CircularIndeterminate />
          </div>
        }


      </AutenticaWrapper>
  
    </>
  )

}

export default Autentica