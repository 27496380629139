import React from "react"
import styled from "styled-components";
import { CustomDropzone } from "../dropzone/Dropzone";
const FormPadrao = styled.div`

 width: 100%;
 padding-top: 10px;
 color: #F49519;

 input{
    max-width: 520px;
    width: 100%;
    border-radius: 9px;
    height: 26px;
    background-color: #F4951926;
    border: 1px solid #F49519;
    padding: 5px;
 }
 input::placeholder {
  color: #F49519;
}
textarea{
    margin-top: 15px;
    max-width: 520px;
    width: 100%;
    border-radius: 9px;
    height: 136px;
    background-color: #F4951926;
    border: 1px solid #F49519;
    padding: 5px;
    margin-bottom: 15px;
 }
  textarea::placeholder {
  color: #F49519;
}
.grupoEst{
    margin: 20px 0 10px 0;
}
.curso{
    margin: 20px 0 20px 0;
}

.link{
    margin-top: 25px;
}

.dropzone{
    margin: 20px 0 20px 0;
    max-width: 520px;
    width: 100%;
    border-radius: 9px;
    padding: 5px;
    align-items: center;
}

`

function AtvComplFormUpload({ children, change = {}, typeAtv = "", placeholdeer = '', curso = false, grupEst = false, ingles = false, titleText = '', descricao = false, prod = false, link = true }) {
    return (
        <FormPadrao>
            {
                !ingles &&
                <input onChange={change} type="text" name="titulo" placeholder={titleText == '' ? "Titulo" : titleText} />
            }
            {
                grupEst &&

                <textarea className="grupoEst" onChange={change} name={"artigo"} placeholder={"Descrição das atividades realizadas e do aprendizado adquirido"}></textarea>

            }

            {
                curso &&

                <input onChange={change} className="curso" type="text" name="instituicao" placeholder="Instituicao" />

            }

            {prod &&
                <textarea onChange={change} name={"descricao"} placeholder={placeholdeer}></textarea>
            }
            {link &&
                <input className="link" onChange={change} type="text" name="link" placeholder="Link para vídeo" />
            }
            <div className="dropzone">
                {children}
            </div>
        </FormPadrao>

    )
}

export default AtvComplFormUpload;