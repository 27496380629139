import React, { useState, useEffect } from "react"
import Banner from "../../components/banner";
import IconButton from "../../components/iconsButton";
import styled from "styled-components";
import IcoNota from "../../fotos/icones/brancos/brancos-02.png"
import NotasComponent from "../../components/notas";
import axios from "axios";
import Header from "../../components/component";
import NotasFrequenciaComponente from "../../components/notas/notasFrequencia";
const PageWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;

    .containerItems{       
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        flex-direction: column;
    }
       
@media (max-width: 1024px){
  .containerItems{  
      max-height: 389px;
      width: 96%;
  }

}
@media (max-width: 979px){
    width: 100%;
    max-width: 685px;

}
@media (max-width: 959px){
    align-items: center;
  .containerItems{
    justify-content: center;  
      
  }

}
@media (max-width: 768px){   
    align-items: center; 
     max-width: 574px;
        
  .containerItems{
    justify-content: flex-start;
    width: 98%;
    //margin-left: 40px;
      
  }

}
@media (max-width: 599px){       
    max-width: 420px;
     padding: 0px 0px;
     .containerItems{
        margin-left: 25px;
        max-height: 250px;
        min-height: 200px;
     }
     
    

}
@media (max-width: 405px){  
    max-width: 317px;
}
  
`


function NotasEscolha() {
    

    useEffect(() => {
     


    }, [])

    return (
        <>
            <PageWrapper>
                <Header loadingSet={false} />
                <Banner button="Notas" img={IcoNota} />
                <div className="containerItems">
                    <NotasFrequenciaComponente  />


                </div>

            </PageWrapper>

        </>
    );

};

export default NotasEscolha