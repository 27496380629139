import React from "react";
import styled from "styled-components";

const AtvComVisuWrapper = styled.div`
     display: flex;
    flex-direction: column;
    max-width: 770px;
    width: 100%; 
    overflow-y: auto;
    max-height: 470px;
    min-height: 272px;
    h1{
        color: #fff
    }

table{
    margin: 5px;
    height: 100%;
}
tr{
    color: #fff
}

th{
    padding: 10px;    
    background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    
   
}


.containerNotas{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.materia{
    color: #F49519;
    background-color: #302114;
    padding: 10px 15px;
}
.nota{
    background-color: #F49519;
    color: #000;    
}
.semestre{
 border-radius: 20px 0px 0px 0px;
}

.media{
 border-radius: 0px 20px 0px 0px;
}
.footIni {
    border-radius: 0px 0px 0px 16px;

}
.footfim {
    border-radius: 0px 0px 16px 0px;

}
 ::-webkit-scrollbar{
     width: 10px;   
 }
 ::-webkit-scrollbar-track{
     background-color: #000;
 }
  ::-webkit-scrollbar-thumb{
     background-color: #000;
     border-radius: 20px;
}
 ::-webkit-scrollbar-thumb:hover{
     background-color: #000;
}
@media (max-width: 1800px){
    max-height: 272px;

}

`
function visualizar({ arr }) {
    let horas = 0

    return (
        <AtvComVisuWrapper>
            <div className="containerNotas">
                {


                    <table width="90%" >
                        <tr>
                            <th className="semestre">Atividade</th>
                            <th className="">Titulo</th>
                            <th className="">Qtd Horas</th>
                            <th className="">Ano</th>
                            <th className="media">Aprovado/Reprovado</th>
                        </tr>
                        {
                            arr.map((i, index) =>{
                              

                                if(i.flg_aprovado == 1){
                                    
                                    if(i.carga_horaria > 100){

                                        horas =  horas  +  (Number(i.carga_horaria) /60)
                                      
                                    }else if(i.carga_horaria == 60 || i.carga_horaria == '60' ){
                                        horas =  horas  +  (Number(i.carga_horaria) /60)
                                    } else{
                                        horas =  horas  +  Number(i.carga_horaria)  /60
                                    }
                                   

                                    
                                }
                                
                               

                            return (
                               
                                <tr>
                                    <td className="materia">{i.tipo_atividade}</td>
                                    <td className="nota" align="center">{i.titulo}</td>

                                    <td className="nota" align="center">
                                        {
                                            Number(i.carga_horaria) >= 60 ? `${Number(i.carga_horaria) / 60}` : `${Number(i.carga_horaria)}`
                                        }

                                        h</td>
                                    <td className="nota" align="center">{i.ano}</td>
                                    <td className="nota" align="center">
                                        {i.flg_aprovado == 2 && 'Reprovado'}
                                        {i.flg_aprovado == 1 && 'Aprovado'}
                                        {i.flg_aprovado == null && 'Aguardando análise'}
                                    </td>

                                </tr>

                            )})
                        }
                        <tfoot>
                            <tr>
                                <th scope="row" className="footIni"></th>
                                <th scope="row"></th>
                                <th scope="row">Horas aprovadas { Math.round(horas)}h </th>
                                <th scope="row"></th>
                                <th scope="row" className="footfim"></th>
                                
                            </tr>
                        </tfoot>

                    </table>



                }


            </div>
        </AtvComVisuWrapper>
    )

}
export default visualizar