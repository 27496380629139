import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Moment from 'react-moment';
const FreqWrapper = styled.div`
    width: 100%;
    height: 100%;    
    overflow-y: auto;
    max-height: 481px;
    min-height: 272px;
    h1{
        color: #fff
    }

table{
    margin: 5px;
    height: 100%;
}
tr{
    color: #fff
}

th{
    padding: 25px;    
    background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    
   
}


.containerNotas{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.materia{
    color: #F49519;
    background-color: #302114;
    padding: 10px 15px;
}
.nota{
    background-color: #F49519;
    color: #000;    
}
.semestre{
 border-radius: 20px 0px 0px 0px;
}

.media{
 border-radius: 0px 20px 0px 0px;
}
.footerFalta{
    border-radius: 0px 0px 20px 0px;
}
.footerComeco{
    border-radius: 0px 0px 0px 20px
}
 ::-webkit-scrollbar{
     width: 10px;   
 }
 ::-webkit-scrollbar-track{
     background-color: #000  ;
 }
  ::-webkit-scrollbar-thumb{
     background-color: #D35D2E;
     border-radius: 20px;
}
 ::-webkit-scrollbar-thumb:hover{
     background-color: #F49519;
}
@media (max-width: 1800px){
    max-height: 272px;

}
@media (max-width: 599px){   
    h1{
        font-size: 18px;
    }
}





`

function FreqComponent({ arr = [], nomeTurma = "" }) {
    useEffect(() => {
        


    })

   
    

    return (
        <FreqWrapper>
            <h1>{nomeTurma}</h1>
            <div className="containerNotas">
                {
                    arr.map((i, index) => {
                        let soma = 0;
                        let soma_faltas = 0;
                        let vale1 = 1;
                        let soma_presenca = 0
                        let totalAula = 0
                       
                        
                       

                        if(i.length > 0)
                        return (
                            <table width="497px" >
                                <tr>
                                    <th className="semestre">{i[0].nome_turma} </th>
                                    <th className="meio">Data</th>
                                    <th className="media">Frequência</th>
                                </tr>
                                {
                                    i.map((n, index) =>{
                                      

                                        let presenca = ''
                                        if ((n.flg_presenca1 == 2 && n.flg_presenca2 == 0) ||
                                            (n.flg_presenca1 == 0 && n.flg_presenca2 == 2) ||
                                            (n.flg_presenca1 == 1 && n.flg_presenca2 == 0) ||
                                            (n.flg_presenca1 == 0 && n.flg_presenca2 == 1)
                                        ) {
                                            presenca = "incompleta";
                                            soma += vale1;
                                        }
                                        if (n.flg_presenca1 == 0 && n.flg_presenca2 == 0) {
                                            presenca = "Sem chamada";
                                            //Caso o professor abra a chamada porém não conclua a chamada, o sistema conta como 1 presença. 
                                            soma += vale1;                                         				  
                                        }
                                        if ((n.flg_presenca1 == 2 && n.flg_presenca2 == 2) || (n.flg_presenca1 == 0 && n.flg_presenca2 == 2) || (n.flg_presenca1 == 2 && n.flg_presenca2 == 0)) {  
                                            presenca = "Presença";                                       
                                            soma_presenca++;

                                        }
                                        if (n.flg_presenca1 == 1 && n.flg_presenca2 == 1){
                                            presenca = "Ausente"
                                            soma_faltas++;
                                        }
                                        if (n.flg_presenca1 == 2 && n.flg_presenca2 == 1)//Meia presença (faltou a segunda aula)
                                        {
                                            presenca = "Atraso ou meia presença";
                                            soma_faltas += 0.5;
                                            soma_presenca += 0.5;
                                        }

                                        if (n.flg_presenca1 == 1 && n.flg_presenca2 == 2)//Meia presença (faltou a primeira aula)
                                        {
                                            presenca = "Atraso ou meia presença";
                                          
                                            soma_faltas += 0.5;
                                            soma_presenca += 0.5;
                                        }
                                        totalAula = i.length;
                                        n.dt_aula = n.dt_aula.split("T")[0]
                                    return (
                                        <tr>
                                            <td className="materia">{n.nm_disciplina}</td>
                                            <td className="nota" align="center"><Moment format="DD/MM/YYYY">{n.dt_aula}</Moment></td>
                                            <td className="nota" align="center">{presenca}</td>

                                        </tr>

                                    )})
                                }
                                <tfoot>
                                    <tr >
                                        <th className="footerComeco">Total e porcetagem de frequência</th>
                                        <th className="meio">Presença: {soma_presenca} 
                                        | {
                                                (100 - ((soma_faltas * 100) / totalAula)).toFixed(2)
                                        
                                        }%   </th>
                                        <th className="footerFalta">Faltas:  
                                        {soma_faltas} | 
                                        {
                                                ((soma_faltas * 100) / totalAula).toFixed(2)

                                        } % </th>
                                    </tr>

                                </tfoot>
                               

                            </table>

                        )
                    })
                }


            </div>
        </FreqWrapper >
    )
}

export default FreqComponent