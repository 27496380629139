import { BrowserRouter, Switch, Route, useHistory, Redirect } from 'react-router-dom'
import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";

function PrivateRoute({ component: Component, ...rest }) {
    const token = localStorage.getItem("token")
    const [tokenExipirado, setToken] = useState(false)

    const validaToken = async () => {

        await axios.get(`${process.env.REACT_APP_API}/valida-token`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async res => {
            const { data = {} } = res.data
            await console.log(data.response)
        }).catch(error => {
            const { response = {} } = error
            const { status = 0 } = response
            if (status == 401) {
                console.log("erro", error)
                window.location.href = "/autenticacao";
            }

            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }


    useEffect(() => {

        //validaToken()



    })

    return (       
            <Route {...rest}
                render={props => localStorage.getItem("token") != null ?
                    (<Component {...props} />) :
                    (<Redirect to={{ pathname: '/autenticacao', state: { from: props.location } }} />)
                } />
      
    )
}

export default PrivateRoute;