import React from "react";
import styled from "styled-components";


const IconWrapper = styled.div`
    width: 217px;
    padding: 10px;  
    border-radius: 10px;
   // margin: 0px 12px 0px 0px;
    height: 200px;
    background-image: linear-gradient(to right, #F49519, #D35D2E );
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  .itemButton{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }

  img{
    width: 45%;
   // height: 100%;
    object-fit: cover;
    
  }
  span{
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
  }
  .containeText{
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .containeTextHelp{
    width: 100%;
    height: 100%;
    text-align: center;
  }

  @media (min-width: 1920px){
  max-width: 212px;
  width: 100%;
  max-height: 197px!important;
  min-height: 197px!important;
  height: 100%;
}

@media (min-width: 1777px){
  max-width: 212px;
  width: 100%;
  max-height: 161px;
  min-height: 161px;
  height: 100%;
}

@media (max-width: 1699px){
    width: 213px;
    height: 124px;
    span{
      font-size: 15px;
    }
    img {
      width: 35%;
      
    }
}
@media (max-width: 1499px){
    width: 213px;
    height: 106px;
    
}
@media (max-width: 1079px){  
  width: 221px;
  margin: 0px 9px 0px 0px;
  height: 179px;
}
@media (max-width: 1069px){
  margin: 0px 5px 0px 0px; 
 // height: 100%;
}
@media (max-width: 1039px){
  max-width: 211px;
  height: 177px;
  margin: 0px 8px 0px 0px;

}
@media (max-width: 1025px){
    max-width: 200px;
    height: 166px;

}
@media (max-width: 979px){
    max-width: 150px;
   

}
@media (max-width: 959px){
    max-width: 180px;
   

}
@media (max-width: 879px){
    max-width: 150px;
   

}
@media (max-width: 768px){
    max-width: 159px;
   margin: 0px 5px 7px 0px;


}
@media (max-width: 599px){
     height: 115px;
     margin: 0px 20px 20px 0px;


}
@media (max-width: 405px){ 
    //max-width: 110px;
    max-height: 96px;

    span{
        font-size: 15px;
    }

  }

`

const IconWrapperHelp = styled.div`
width: 217px;
padding: 10px 8px;  
border-radius: 10px;
//margin: 0px 12px 0px 0px;
// height: 200px;
background-image: linear-gradient(to right, #F49519, #D35D2E );
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

  .itemButton{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }

  .itemButtonHelp{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  img{
    width: 45%;
   // height: 100%;
    object-fit: cover;
    
  }
  span{
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
  }
  .containeText{
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .containeTextHelp{
    width: 100%;
    height: 100%;
    text-align: center;
  }

  @media (min-width: 1920px){
  max-width: 212px;
  width: 100%;
  max-height: 197px!important;
  min-height: 197px!important;
  height: 100%;
}

.itemButtonHelp{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.botaoDireita{
  max-width: 300px;
   background-image: linear-gradient(to right,  #D35D2E, #F49519 );
  // width: 100%;
  padding: 2px 10px 2px 10px;
  height: 30px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.botaoEsquerdo{
max-width: 300px;
background-image: linear-gradient(to right,  #D35D2E, #F49519 );
//  width: 100%;
padding: 5px;
height: 30px;
border-radius: 18px;
display: flex;
align-items: center;
justify-content: center;

}
@media (max-width: 825px){
  width: 166px;

}
@media (max-width: 599px){
  height: 148px;
  margin: 0px 20px 20px 0px;

}
`


function IconButton({ img = '', text = '', link = '', targetBlank = false }) {
  return (
    <>
      <a href={link} target={targetBlank ? '_blank' : ''} className="newButtom">
        <IconWrapper>
          <div className="itemButton">
            <img src={img} />
            <div className="containeText">
              <span>{text}</span>
            </div>
          </div>
        </IconWrapper>

      </a>

    </>
  )

}

export function IconButtonHelp({ img = '', text = '', link = '', targetBlank = false }) {
  return (
    <>
      <a href={link} target={targetBlank ? '_blank' : ''} className="newButtom">
        <IconWrapperHelp>
          <div className="itemButtonHelp">
            <div>
              <span>{text}</span>
            </div>
            <img src={img} style={{width: '35px'}}/>
          </div>
          <div>
            <a className="botaoDireita" href="https://teams.microsoft.com/l/chat/0/0?users=suporte@melies.com.br" target="_blank">
              <span style={{fontSize: '11px'}}>Solicitar suporte técnico</span>
            </a>
            <a className="botaoEsquerdo" href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fteam%2F19%3Ac268aa4fb8354348ae001af93896af9b%40thread.tacv2%2Fconversations%3FgroupId%3Ddaa041e8-68c7-434d-b5b1-3fd2792d1dd5%26tenantId%3Deba1c994-c3c8-4e60-b3d7-aa339e9ed490&type=team&deeplinkId=7b323fb0-d192-4725-9741-955510ef7941&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true" target="_blank">
               <span style={{fontSize: '11px'}}>Plantão de dúvidas </span>

            </a>
            <a className="botaoDireita" href="agenda-professor" target="">
               <span style={{fontSize: '11px'}}>Booking Professores </span>
            </a>
          </div>
        </IconWrapperHelp>
      </a>

    </>
  )

}

export default IconButton
