import * as React from 'react';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageAvatars from '../avatar';
import Form from '../util/form'
import FormLicense from '../util/formLicense'
import TableList from '../table/'
import TableListSoft from '../table/tableListSoft'
import axios from "axios";
import Stack from '@mui/material/Stack';

import Alert from '@mui/material/Alert';
export default function BoxForm(props) {
    const { setLoading } = props    
    const matches = useMediaQuery('(max-width:956px)')    
    const [alert, setAlert] = useState({ type: 'success', msg: 'Obrigado por sua Avaliação', alertVerify: false })    
    const [formOpen, setOpen] = useState(false)
    const [listSoft, setListSoftw] = useState(true)
    const [listlicense, setListlicense] = useState(false)
    const [formOpenLicense, setOpenLicense] = useState(false)
    const [software, setSoftware] = useState([])
    const [license, setlincese] = useState([])
    const [editSoft, setSoft] = useState()
    const [editLice, setLic] = useState()
    useEffect(() => {
        getSoftware()
        getlicense()
    },[])

   const alertMsg = async (msg,type,reqType) =>{
       reqType == 1 ? getSoftware() : getlicense() 
   
    await setAlert({type: type, msg:msg, alertVerify: true})
       setTimeout(() => {
           setAlert({ type: 'success', msg: '', alertVerify: false })
       }, "4000")

   }
    const getSoftware = async () => {

        await axios.get(`${process.env.REACT_APP_API}/softwareTable `, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {} } = response.data
            await setSoftware(data)
        }).catch(error => {
            console.log("erro", error)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }
    const getlicense = async () => {
        await axios.get(`${process.env.REACT_APP_API}/license`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {} } = response.data
            await setlincese(data)


        }).catch(error => {
            console.log("erro", error)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }

    const openForm = async () =>{        
        formOpen ? setOpen(false) :  setOpen(true)
    }
    const openLicense = async () => {
        formOpenLicense ? setOpenLicense(false) : setOpenLicense(true)
    }
    const changeList = async (number) => {            
        if (number == 1 && listlicense) {
           await setListSoftw(true)
           await setListlicense(false)
        } else if (number == 2 && listSoft) {
         
            await setListlicense(true)
            await setListSoftw(false)
        }
     }
     const editSoftware = async (id) =>{
          await axios.get(`${process.env.REACT_APP_API}/software/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {} } = response.data            
            await setSoft(data[0])
            setOpen(true)


        }).catch(error => {
            console.log("erro", error)
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
     }
     const editLicense = async (id) =>{
         await axios.get(`${process.env.REACT_APP_API}/license/${id}`, {
             headers: {
                 'Content-Type': 'application/json',
                 'Access-Control-Allow-Origin': '*',
                 'Access-Control-Allow-Methods': 'POST, GET',
                 'Access-Control-Allow-Headers': '*',
             },
         }).then(async response => {
             const { data = {} } = response.data
             
             if(data[0].data_compra != null) data[0].data_compra = data[0].data_compra.split("T")[0]
            if(data[0].validade !=null) data[0].validade = data[0].validade.split("T")[0]
             await setLic(data[0])
             setOpenLicense(true)


         }).catch(error => {
             console.log("erro", error)
             // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

         })

     }

   

    return (
      
        <>
            <div style={{ ...styles.containerButton, ...matches ? { flexDirection: 'column' } : '' }}  >
          
                <ImageAvatars title={'Méliès'} />
               
                <div style={styles.containerTable } >
                    <div style={{ marginBottom: 30  }}>
                        <span style={{ ...styles.change, ...listSoft ? { background: '#f5f5f5' } : '' }} onClick={() => changeList(1)} >Lista de software</span>
                        <span style={{ ...styles.change, ...listlicense ? { background: '#f5f5f5' } : '' }} onClick={() => changeList(2)} >Lista de Licenças </span>
                    </div>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        
                            {
                                 listSoft ?
                                    
                                <TableListSoft software={software} edit={editSoftware} alertMsg={alertMsg} />
                                 
                                 :
                                
                                <TableList license={license} edit={editLicense} alertMsg={alertMsg} />
                              
                            }
                        
                    
                    </div>
                </div>
            <div style={{ ...styles.containeMap, ...matches ? { padding: 0 } : '' }}>                
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" onClick={() => openForm()}>Adicionar Software</Button>                      
                        <Button variant="outlined" href="#outlined-buttons" onClick={() => openLicense()}>Adicionar Licença  </Button>
                    </Stack>

            </div>    

        </div>
        {
                formOpen &&
                <Form formOpe={openForm} alertMsg={alertMsg} setLoading={setLoading} edit={editSoft}/>
        }
        {
                formOpenLicense&&
                <FormLicense exit={openLicense} alertMsg={alertMsg} setLoading={setLoading} edit={editLice}/>
        }
            {
                alert.alertVerify &&
                <Alert style={{ position: 'absolute', bottom: 20, left: 50 }} severity={alert.type}>{alert.msg}</Alert>
            }
        </>
        
        
        )


}
const styles = {
    containerButton: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',

    },
    containerTable: {        
        width: '100%'
    },
    change: {
        padding: 10,
        border: '2px solid #f5f5f5',
        cursor: 'pointer'
    },
    containeMap: {
        padding: '20px 0px',
        width: '100%'
    },
    group: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 300,
        height: '100%',
        width: '100%'

    },
    contentButton: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column'
    },
    calcule: {
        background: '#17cc00',
        color: '#fff',
        margin: 5
    },
    recalcule: {
        background: '#0300cc',
        color: '#fff',
        margin: 5
    },
    containeInfo: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20
    },
    maps: {
        maxWidth: 650,
        maxHeight: 450,
        width: '100%',
        height: '100%',
        border: 0
    },
    add:{
        position: "absolute",
        top: 16,
        right: 19,
        cursor: "pointer",
        
    
    }

}