import React from "react";
import styled from "styled-components";

const AjudaComponentWrapper = styled.div`
width: 100%;
height: 100%;    
overflow-y: auto;
max-height: 239px;
min-height: 272px;
display: flex;
flex-wrap: wrap;
padding: 45px;

.botaoDireita{
    max-width: 350px;
     background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    width: 100%;
    padding: 15px;
    height: 50px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.botaoEsquerdo{
    max-width: 350px;
    background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    width: 100%;
    padding: 15px;
    height: 50px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

}
span{
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}
a{
    text-decoration: none;
    
}


`



function AjudaComponente() {

    return(
        <AjudaComponentWrapper>
            <a className="botaoDireita" href="https://teams.microsoft.com/l/chat/0/0?users=suporte@melies.com.br" target="_blank">
             <span>Solicitar suporte técnico</span> 
            </a>
            <a className="botaoEsquerdo" href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fteam%2F19%3Ac268aa4fb8354348ae001af93896af9b%40thread.tacv2%2Fconversations%3FgroupId%3Ddaa041e8-68c7-434d-b5b1-3fd2792d1dd5%26tenantId%3Deba1c994-c3c8-4e60-b3d7-aa339e9ed490&type=team&deeplinkId=7b323fb0-d192-4725-9741-955510ef7941&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true" target="_blank">
            <span>Plantão de dúvidas </span>

            </a>
            <a className="botaoDireita" href="agenda-professor" target="">
                <span>Booking Professores </span>

            </a>
         

           
        
        </AjudaComponentWrapper>
    )

    
}

export default AjudaComponente
