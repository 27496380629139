import React from "react";
import styled from "styled-components";
import axios from "axios";
import BibliotecaComponente from "../../components/biblioteca";
import Banner from "../../components/banner";
import Header from "../../components/component";
import IconSix from "../../fotos/icones/brancos/brancos-06.png"


const BibliotecaWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding:0 30px;

    .containerItems{       
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }


`

function Biblioteca() {

    return (
        <BibliotecaWrapper>
            <Header />
            <Banner button="Biblioteca" img={IconSix} />
            <div className="containerItems">

                <BibliotecaComponente />


            </div>

        </BibliotecaWrapper>
    )

}
export default Biblioteca