import React, { useState, useEffect } from "react"
import styled from "styled-components";
import Moment from 'react-moment';

const BoletoComponetWrapper = styled.div`
width: 100%;
height: 100%;  
max-height: 470px;
min-height: 272px;

span{
    color: #fff;
    font-weight: 600;
}
a{
    text-decoration: none;
}

.containerBoleto{
    width: 100%;    
    display: flex;
        flex-direction: column;
    

}
.containerItem{
     background-image: linear-gradient(to right,  #D35D2E, #F49519 );
     padding: 25px;
     border-radius: 10px;
}
.containerCurso{
    background-image: linear-gradient(to right,  #D35D2E, #F49519 );
   // padding: 25px;
    border-radius: 10px;
    max-width: 692px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    

}
.containerImprimir{
    background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    padding: 25px;
    border-radius: 10px;

}
.containerItemBoleto{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
 
}
.nullBoleto{
    background-color: #302114;
    padding: 20px;
    border-radius: 16px;
    text-align: center;
    border: 1px solid #F49519;
}
.nullBoleto label{
    text-transform: uppercase;
    font-size: 20px;
    
}
.boletoAtrasado{
   background-image: linear-gradient(to right,  #FF0000, #FF6347 );
}
 
@media (max-width: 1800px){
    max-height: 272px;

}
@media (max-width: 1064px){
    .containerCurso { 
        max-width: 684px;
    }

}
@media (max-width: 1024px){
    .containerItemBoleto{
        justify-content: center;
    }
    .containerItem{
        margin-right: 3px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .containerCurso{
        margin-right: 3px;
    }
    .containerCurso { 
        max-width: 684px;
    }

}
@media (max-width: 975px){
    span{
        font-size: 14px;
    }

}
@media (max-width: 768px){
    .containerCurso{
       flex-wrap: wrap;
    }

}



`

function BoletoComponet({ boleto = [] }) {
    let [dataAgora, setData] = useState(0)
    useEffect(() => {
        const agora = new Date();    
        setData(agora.toISOString())

    }, [])


    return (
        <BoletoComponetWrapper>
            <div className="containerBoleto">
                {
                    boleto.length > 0 ?
                    boleto.map((i) => {
                        i.dt_inicial = i.dt_inicial.split("T")[0]
                        dataAgora = dataAgora.split("T")[0]
                      
                        return (

                            <a href={`https://admmelies.com.br/AdmMelies/site/dados_superpay_boleto_faculdade.php?id=${i.cd_parc_mat}&cd_interesse_index=${i.cd_interesse_index}`} target="_blank">
                            <div className="containerItemBoleto">
                                
                                <div className={`containerItem ${dataAgora > i.dt_inicial && ` boletoAtrasado`}` } > <span>Boleto</span> <span>{i.cd_parc_mat} </span> 
                                </div>
                               

                                <div className={`containerCurso ${dataAgora > i.dt_inicial && ` boletoAtrasado`}`}> 
                                    <span>{i.nome} &nbsp; {i.nome_turma} &nbsp; </span>
                                 <span><Moment format="DD/MM/YYYY">
                                    {i.dt_inicial}
                                    </Moment>
                                </span>
                                </div>
                                

                                    <div className={`containerImprimir ${dataAgora > i.dt_inicial && ` boletoAtrasado`}`}><span>imprimir</span></div>

                                


                            </div>
                            </a>
                        )
                    })
                    :
                    <div className="nullBoleto"> <label>Nenhum boleto disponivel!</label> </div>
                }

            </div>

        </BoletoComponetWrapper>
    )

}
export default BoletoComponet