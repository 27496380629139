import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import LogoMelies from "../../fotos/logo/logo_degrade_quente_linhas_pretas.svg"
export default function ImageAvatars(props) {
    const matches = useMediaQuery('(max-width:500px)')

    const { title = '', src = '' } = props
    return (
        <div style={styles.containerImg}>
            <img style={styles.img} src={LogoMelies} />

        </div>
    );
}
const styles = {
  containerImg:{
        position: "absolute",
        width: "100%" , 
        maxWidth: 100,    
        top: 14,
        left: 20
  },
  img:{
    width: "100%"
  }
}