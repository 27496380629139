import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import AtvComplFormPadrao from "./atvComplFormPadrao";
import AtvComplFormCheck from "./atvComplFormCheck";
import AtvComplFormUpload from "./atvComplFormUpload";
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { CustomDropzone } from "../dropzone/Dropzone";
import { Backdrop, CircularProgress } from "@mui/material";

const AtvComplComponetWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 684px;
    width: 100%;

    label{
        color: #fff;
    }
.css-dcrfqy-MuiPaper-root-MuiAlert-root{
    background-color: #F4951926;
}

.containerItemsAtiv{
   display: flex;
   align-items: center;
  // flex-wrap: wrap;
}
.containerItemsAtiv .containerSelect{
    margin-left: 15px;
    max-width: 438px;
    width: 100%;

}

.containerItemsAtiv .containerSelect .atividades{
 
    height: 40px;    
    width: 100%;
    background-image: linear-gradient(to right, #F49519, #D35D2E );
    border-radius: 9px;
    
}
.horas{
    width: 70px;
    height: 40px;
    border-radius: 9px;
    background-image: linear-gradient(to right,#5950E9,#338FF1 );
    align-items: center;
    display: flex;
    padding: 5px;
    color: #fff;

}
.containerBotao{
    display: flex;
    max-width: 532px;
    width: 100%;
    justify-content: flex-end;
}
.botao{
    background-image: linear-gradient(to right, #F49519, #D35D2E );
    margin: 8px;
    display: flex;
    padding: 10px;
    border-radius: 9px;
    color: #fff;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    

}
.horas::placeholder{
    color: #fff;
}
.containerHoras{
    display: flex;
    flex-direction: column;
}
@media (max-width: 599px){ 
    .containerItemsAtiv {
        flex-direction: column;
        align-items: baseline;
     }
    .containerBotao{  
       justify-content: flex-start;
    }
    .containerItemsAtiv .containerSelect {
        margin-left: 0px; 
        max-width: 371px;

    }
}

`

function AtivComplComponent({ click = {}, botaVerMais = '', atualizarEnv, abrirCarregar = {} }) {
    const [atvType, setType] = useState([
        { atv: 'Atividade Institucional', horas: 10 * 60, type: 1 },
        { atv: 'Curso', horas: 40 * 60, type: 2 },
        { atv: 'Monitoria', horas: 20 * 60, type: 3 },
        { atv: 'Grupo de Estudo', horas: 20 * 60, type: 4 },
        { atv: 'Feira e Congresso', horas: 20 * 60, type: 5 },
        { atv: 'Palestra', horas: 3 * 60, type: 6 },
        { atv: 'Estágio', horas: '1000000', type: 7 },
        { atv: 'Museu', horas: 3 * 60, type: 8 },
        { atv: 'Teatro/Show', horas: 3 * 60, type: 9 },
        { atv: 'Produção Artística', horas: 40 * 60, type: 10 },
        { atv: 'Filme', horas: 5 * 60, type: 11 },
        { atv: 'Livro', horas: 20 * 60, type: 12 },
        { atv: 'Jogo', horas: 2 * 60, type: 13 },
        { atv: 'Seriado', horas: 2 * 60, type: 14 },
        { atv: 'Outros', horas: 1 * 60, type: 17 },
        { atv: 'Nivelamento de inglês', horas: '1000000', type: 15 },
        { atv: 'NIC', horas: '1000000', type: 16 },

    ])
    const [form, setForm] = useState({ titulo: '', atv: 'Atividade Institucional', descricao: '', instituicao: '', link: '', horas: 0, ano: 0 })
    const [typeAtvChange, setChange] = useState('')
    const [check, setCheck] = useState({ typeAtv: 0, placeholder: '', checkUm: '', checkDois: '', checkTres: '',checkQuatro: '', title: '' })
    const [alert, setAlert] = useState({ msg: '', openAlert: false, type: 'success' })
    const [upload, setUp] = useState({ curso: false, grupEst: false, ingles: true, titleText: '', placeholder: '', descricao: true, prod: false, link: true })
    const [buttonOpen, setButton] = useState(true)
    const [padrao, setPadrao] = useState({ title: '', placeholder: 'Descrição das atividades realizadas e do aprendizado adquirido', type: 1 })
    const [changeAtv, setChangeAtv] = useState("Atividade Institucional")
    const [arquivoAtivCompl, setArquivoAtivCompl] = useState([])
    const [loading, setLoading] = useState(false)
    const escolher = async (e = {}) => {
        const { name, value } = e.target
        let obj = await atvType.filter((item) => item.type == value)


        await setChangeAtv(obj[0].atv)
        await horasSet('');
        await setButton(true)


        if (value == 3 || value == 6 || value == 8 || value == 9 || value == 11 || value == 12 || value == 13 || value == 14 || value == 17) {
            if (value == 3) {
                await setPadrao({ title: '', placeholder: 'Descrição das atividades realizadas e do aprendizado adquirido', type: 0 })
            }

            if (value == 6) {
                await setPadrao({ title: 'Título da palestra', placeholder: 'Dissertação sobre o conteúdo da palestra e o aprendizado adquirido', type: 1 })
            }
            if (value == 8) {
                await setPadrao({ title: 'Título da exposição', placeholder: 'Dissertação sobre o conteúdo da exposição e o aprendizado adquirido', type: 1 })
            }
            if (value == 9) {
                await setPadrao({ title: 'Título do espetáculo', placeholder: 'Dissertação sobre o conteúdo do espetáculo e o aprendizado adquirido', type: 1 })
            }
            if (value == 11) {
                await setPadrao({ title: 'Título do filme', placeholder: 'Análise dos conteúdos da obra em relação aos conteúdos apreendidos no curso', type: 1 })
            }
            if (value == 12) {
                await setPadrao({ title: 'Título do livro', placeholder: 'Análise dos conteúdos da obra em relação aos conteúdos apreendidos no curso', type: 1 })
            }
            if (value == 13) {
                await setPadrao({ title: 'Título do jogo', placeholder: 'Análise dos conteúdos da obra em relação aos conteúdos apreendidos no curso', type: 1 })
            }
            if (value == 14) {
                await setPadrao({ title: 'Título do seriado', placeholder: 'Análise dos conteúdos da obra em relação aos conteúdos apreendidos no curso', type: 1 })
            }

            if (value == 15) {
                await setPadrao({ title: 'Descrição das atividades realizadas', type: 1 })
            }
            if (value == 17) {
                await setPadrao({ title: 'Título', placeholder: 'Descrição das atividades realizadas e do aprendizado adquirido', type: 1 })
            }
            await setChange('padrão')
            await setForm({
                ...form,
                horas: obj[0].horas,
            })

        } else if (value == 1 || value == 5) {
            if (value == 1) {
                await setCheck({
                    typeAtv: "atividade-institucional",
                    placeholder: 'Descrição das atividades realizadas',
                    checkUm: "Representante de turma",
                    checkDois: "Membro da CPA",
                    checkTres: "Membro de colegiado",
                    checkQuatro: "Empresa Júnior",
                    typeAtv: 1
                })
            } else if (value == 5) {
                await setCheck({
                    typeAtv: "feira-congresso",
                    placeholder: 'Descrição das atividades realizadas e do aprendizado adquirido',
                    checkUm: "Organização",
                    checkDois: "Participação",
                    checkTres: "Apresentação",
                    typeAtv: 0,
                    title: 'Titulo do evento'
                })


            }
            await setChange('check')

        } else if (value == 2 || value == 4 || value == 7 || value == 15 || value == 16) {

            await setButton(false)
            if (value == 2) {
                await setUp({ curso: true, link: false })


            } else if (value == 4) {

                await setUp({ grupEst: true, titleText: 'Nome do grupo de estudos', link: false })

            } else {
                await setUp({ curso: false, grupEst: false, link: false })

            }
            if (value == 7) {
                await setUp({ titleText: 'Nome da empresa', link: false })
            }

            if (value == 15) {

                await setUp({ ingles: true, descricao: true, link: false })
            }
            if (value == 16) {
                await setUp({ ingles: true, descricao: true, link: false })

            }


            await setChange('upload')

        } else if (value == 10) {
            await setUp({ curso: false, ingles: true, titleText: 'Título da obra', placeholder: 'Dissertação sobre o processo produção da obra', prod: true })
            await setChange('upload')
            await setButton(false)

        }


    }

    const onchangeForm = async (e) => {
        const { name, value } = e.target

        await setForm({
            ...form,
            [name]: value,

        })


    }

    const verifyHoras = async () => {
        let obj = await atvType.filter((item) => item.atv == changeAtv)
        try {


            if (Number(form.horas) > Number(obj[0].horas / 60)) {
                await setAlert({ msg: `Quantidade máxima de horas permitida e de ${obj[0].horas / 60}h`, openAlert: true, type: "warning" })
                setTimeout(async () => {
                    await setAlert({ msg: "", openAlert: false })
                }, 5000);
                await setForm({
                    ...form,
                    horas: 0,

                })

            }
            if (Number(form.horas) <= 0) {
                await setAlert({ msg: `Error ao informar quantidade de hora`, openAlert: true, type: "warning" })
                setTimeout(async () => {
                    await setAlert({ msg: "", openAlert: false })
                }, 5000);
                await setForm({
                    ...form,
                    horas: 0,

                })
            }

        } catch (error) {
            return error
        }


    }
    const enviar = async () => {
        try {
            abrirCarregar()
            const dataAtual = new Date();
            form.ano = dataAtual.getFullYear();
            if (form.instituicao == '' || form.instituicao == undefined) {
                form.instituicao = 'instituicao'
            }
            if (form.horas == 0 || form.length == 0) {
                setAlert({ msg: "Por favor, escolher uma atividade complementar", openAlert: true, type: "warning" });
                return
            }
            form.atv = changeAtv
            form.horas = !buttonOpen ? form.horas * 60 : form.horas

            const token = localStorage.getItem("token")
            const fileIds = arquivoAtivCompl?.map(arquivo => arquivo.fileId);
            const fileIdString = fileIds.join(',');
            let query = `?arquivoAtivCompl=${fileIdString}`;
            const response = await axios.post(`${process.env.REACT_APP_API}/atividades-complementar${query}`, form, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST, GET',
                    'Access-Control-Allow-Headers': '*',
                },
            })

            const { data = {}, status = false } = response.data
            if (response?.status === 200) {
                await setAlert({ msg: "Atividade salva, e enviada ao professor com sucesso!", openAlert: true, type: "success" })
                setForm({ titulo: '', atv: 'Atividade Institucional', descricao: '', instituicao: '', link: '', horas: 0, ano: 0 })
                setArquivoAtivCompl([])
                return data
            }

            setAlert({ msg: "Houver algum erro tente novamente mais tarde!", openAlert: true, type: "error" })
            return data;

        } catch (error) {
            console.log("erro", error)
            window.location.href = "/autenticacao";
            setAlert({ msg: "Houver algum erro tente novamente mais tarde!", openAlert: true, type: "error" })
            return error;
        } finally {
            setTimeout(() => {
                setAlert({ msg: "", openAlert: false })
            }, 5000)
        }

    }

    const changeRadio = async (e) => {
        const { name, value } = e.target
        await setForm({
            ...form,
            [name]: value
        })
        horasSet(value)


    }
    const horasSet = async (value) => {
        let valHoras = horasComple(value);
        await setForm({
            ...form,
            horas: valHoras
        })

    }
    const horasComple = (tipo) => {

        switch (tipo) {
            case 'Representante de turma':
                return 10 * 60
            case 'Membro da CPA':
                return 5 * 60
            case 'Membro de colegiado':
                return 5 * 60
            case 'Organização':
                return 6 * 60
            case 'Participação':
                return 1 * 60
            case 'Apresentação':
                return 5 * 60
            case 'Empresa Júnior':
                return 10 *60
            
            default:
                return '0';
        }

    }
    const aoCarregarPag = async () => {
        await setCheck({
            typeAtv: "atividade-institucional",
            placeholder: 'Descrição das atividades realizadas',
            checkUm: "Representante de turma",
            checkDois: "Membro da CPA",
            checkTres: "Membro de colegiado",
            checkQuatro: "Empresa Júnior",
            typeAtv: 1
        })
        await setForm({ titulo: '', atv: 'Atividade Institucional', descricao: '', instituicao: '', link: '', horas: 0, ano: 0 })
        await setChange('check')

    }

    useEffect(() => {
        aoCarregarPag()
    }, [])

    const handleRemoveFile = async (fileId) => {
        setLoading(true)
        try {
            const token = localStorage.getItem("token")
            const response = await axios.delete(`${process.env.REACT_APP_API}/arquivo-atividades-complementar/${fileId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST, GET, DELETE',
                    'Access-Control-Allow-Headers': '*',
                },
            })
            if (response?.status === 200) {
                setArquivoAtivCompl(prevArquivos => prevArquivos?.filter(file => file.fileId !== fileId));
                setAlert({ msg: "Arquivo excluido!", openAlert: true, type: "success" })
            }
        } catch (error) {
            console.log("erro", error)
            setAlert({ msg: "Houver algum erro ao excluir arquivo!", openAlert: true, type: "error" })
            return error;
        } finally {
            setTimeout(() => {
                setAlert({ msg: "", openAlert: false })
            }, 5000)
            setLoading(false)
        }
    }

    return (
        <AtvComplComponetWrapper>
            <div className="containerItemsAtiv">
                <div className="containerHoras">
                    <InputLabel id="demo-controlled-open-select-label">Horas</InputLabel>
                    <input className="horas"  max={changeAtv === 'Produção Artística' ? 40 : undefined}  type="number" name="horas" value={buttonOpen ? form.horas / 60 : form.horas} onChange={onchangeForm} onBlur={verifyHoras} disabled={buttonOpen} />
                </div>

                <div className="containerSelect">
                    <InputLabel id="demo-controlled-open-select-label">Escolha a atividade complementar</InputLabel>
                    <Select
                        className="atividades"
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        //  open={open}
                        // onClose={handleClose}
                        // onOpen={handleOpen}
                        name="atv"
                        // value={changeAtv}
                        label="Age"
                        defaultValue={1}
                        onChange={escolher}
                    >

                        {
                            atvType.map((item) => (
                                <MenuItem value={item.type}>{item.atv}</MenuItem>

                            ))
                        }
                    </Select>
                </div>
            </div>
            {
                typeAtvChange == 'padrão' &&
                <AtvComplFormPadrao
                    change={onchangeForm}
                    type={padrao.type}
                    typeAtv={"textarea"}
                    title={padrao.title}
                    placeholder={padrao.placeholder} />
            }

            {
                typeAtvChange == 'check' &&
                <AtvComplFormCheck
                    change={onchangeForm}
                    click={changeRadio}
                    typeAtv={"textarea"}
                    placeholder={check.placeholder}
                    checkUm={check.checkUm}
                    checkDois={check.checkDois}
                    checkTres={check.checkTres}
                    checkQuatro={check.checkQuatro}
                    type={check.typeAtv}
                    title={check.title}
                />


            }
            {
                typeAtvChange == 'upload' &&
                <AtvComplFormUpload change={onchangeForm}
                    typeAtv={"textarea"}
                    placeholder="Dissertação sobre a atividade"
                    curso={upload.curso}
                    grupEst={upload.grupEst}
                    ingles={upload.ingles}
                    prod={upload.prod}
                    descricao={upload.descricao}
                    titleText={upload.titleText}
                    placeholdeer={upload.placeholder}
                    link={upload.link}
                >
                    <CustomDropzone
                        txt={'Arraste ou clique para subir o arquivo em pdf ou Jpg'}
                        callback={(file) => {
                            if (file.status === 201) {
                                setArquivoAtivCompl(prevArquivos => [...prevArquivos, file]);

                            }
                        }}
                        propsDropzone={{
                            style: {
                                border: `1px dotted rgb(244, 149, 25)`,
                                padding: '5px',
                                maxWidth: '520px',
                                width: '100%',
                                // marginRight: '50px',
                                borderRadius: '9px'
                            }
                        }}
                    />
                </AtvComplFormUpload>

            }
            <div style={{ display: 'flex', gap: 15, flexWrap: 'wrap', margin: '10px 0px 20px 0px' }}>
                {arquivoAtivCompl.length > 0 &&
                    arquivoAtivCompl?.map((item, index) => {
                        return (
                            <div key={index} style={{
                                display: 'flex', border: '1px dotted #eaeaea', padding: '5px', marginTop: '20px', alignItems: 'start', flexDirection: 'column', justifyContent: 'center', maxWidth: '160px', position: 'relative'
                            }}>
                                <div style={{
                                    width: 20,
                                    height: 20,
                                    backgroundColor: 'red',
                                    borderRadius: 20,
                                    color: '#fff',
                                    position: 'absolute',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    top: -5,
                                    right: -5,
                                    transition: ".3s",
                                    cursor: "pointer",
                                }} onClick={() => handleRemoveFile(item?.fileId)}>
                                    <p style={{
                                        textAlign: 'center', marginTop: 8,
                                        marginRight: 2,
                                    }}>x</p>
                                </div>
                                <a href={item?.filePreview} target="_blank" style={{
                                    textDecoration: 'none', textOverflow: 'ellipsis',
                                    overflow: 'hidden', maxWidth: '160px'
                                }}>
                                    <div
                                        style={{
                                            backgroundImage: item.fileName?.includes('pdf') ? `url('/images/pdf_icon.png')` : `url('${item?.filePreview}')`,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center center',
                                            width: 150,
                                            aspectRatio: '1/1',
                                        }}>
                                    </div>
                                    <p style={{
                                        color: '#fff', fontSize: '11px', textOverflow: 'ellipsis',
                                        overflow: 'hidden', textAlign: 'center'
                                    }}>{item?.fileName}</p>
                                </a>
                            </div>
                        )
                    })}
            </div>
            <div className="containerBotao">
                <div className="botao" onClick={enviar}>Enviar</div>
                <div className="botao" onClick={click}>{botaVerMais}</div>

            </div>
            {
                alert.openAlert &&
                <Alert variant="outlined" severity={alert?.type}>
                    <label>{alert?.msg}</label>
                </Alert>
            }

            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>
        </AtvComplComponetWrapper>

    )
}

export default AtivComplComponent;