import React, { useState, useEffect } from "react"
import Banner from "../../components/banner";
import IconButton from "../../components/iconsButton";
import styled from "styled-components";
import IconOne from "../../fotos/icones/brancos/brancos-01.png"
import axios from "axios";
import Header from "../../components/component";
import AtivComplComponent from "../../components/atividade-complementar"
import Visualizar from "../../components/atividade-complementar/visualizarAtvCompl";
const AtvComWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;

    .containerItems{       
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 96%;
        flex-direction: row;        
        justify-content: space-between;
        height: 100%;
    }
    .containerButton .contentItem img {
        width: 36%!important;
    }
    
::-webkit-scrollbar{
     width: 10px;   
 }
 ::-webkit-scrollbar-track{
     background-color: #000;
 }
  ::-webkit-scrollbar-thumb{
     background-color: #000;
     border-radius: 20px;
}
 ::-webkit-scrollbar-thumb:hover{
     background-color: #000;
}
@media (max-width: 1024px){
  .containerItems{  
      max-height: 389px;
      width: 96%;
  }

}
@media (max-width: 979px){
    width: 100%;
    max-width: 685px;

}
@media (max-width: 959px){
    align-items: center;
  .containerItems{
    justify-content: center;  
      
  }

}
@media (max-width: 768px){   
    align-items: center; 
     max-width: 574px;
        
  .containerItems{
    justify-content: flex-start;
    width: 98%;
    //margin-left: 40px;
      
  }

}
@media (max-width: 599px){       
    max-width: 420px;
     padding: 0px 0px;
     .containerItems{
        margin-left: 25px;
        max-height: 250px;
        min-height: 200px;
     }
     
    

}
@media (max-width: 405px){  
    max-width: 317px;
}
  
`

function AtivComple() {
const [atv, setData] = useState([])
const [verAtv, setVer] = useState(false)
const [text, setText] = useState("Ver enviadas")
const [loading, setLoading] = useState(false)

const pegarAtividade = async () =>{
    const token = localStorage.getItem("token") 
  
    await axios.get(`${process.env.REACT_APP_API}/atividades-complementar `, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET',
            'Access-Control-Allow-Headers': '*',
        },
    }).then(async response => {
        const { data = {} } = response.data
       await setData(data)
     

    }).catch(error => {
        const { response = {} } = error
        const { status = 0 } = response
        if (status == 401) {
            console.log("erro", error)
            window.location.href = "/autenticacao";
        }
        // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

    })
}
const carregar = async () =>{
    await setLoading(true)
    setTimeout(async () => {
        await setLoading(false)
    }, 5000);

}
const verMaisAtv = async ()=>{
    await setLoading(true)
    setTimeout(async () => {
        await setLoading(false)
    }, 5000);
    if (verAtv){
        setVer(false)
        setText("Ver enviadas")
    }else{
        setVer(true)
        setText("Fechar enviadas")
    }
}
    useEffect(() => {
        pegarAtividade()

    }, [])
  

    return (
        <AtvComWrapper>
            <Header loadingSet={loading} />
            <Banner button="Atividade Complementar" img={IconOne} />
            <div className="containerItems">
                <AtivComplComponent click={verMaisAtv} botaVerMais={text} atualizarEnv={pegarAtividade} abrirCarregar={carregar}/>
                { verAtv &&
                    <Visualizar arr={atv} />

                }
                
            </div>

        </AtvComWrapper>
    )
}
export default AtivComple;