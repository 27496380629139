import React from "react";
import styled from "styled-components";
import axios from "axios";
import IconThree from "../../fotos/icones/brancos/brancos-03.png"
import AjudaComponente from "../../components/ajuda/ajuda";
import Banner from "../../components/banner";
import Header from "../../components/component";


const AjudaWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;
.containerItems{  
        max-width: 981px;     
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }
`

function Ajuda() {

    return(
        <AjudaWrapper>
            <Header />
            <Banner button="Ajuda" img={IconThree} />
            <div className="containerItems">
               
            <AjudaComponente />


            </div>
        
        </AjudaWrapper>
    )
    
}
export default Ajuda