import React from "react";
import styled from "styled-components";

const AvisoWrapper = styled.div`
margin-top: 20px;
width: 100%;
display: flex;
flex-direction: column;
height: 100%;
overflow: auto;

.containerAviso{
    width: 99%;
    background-image: linear-gradient(to right, #F49519, #D35D2E );
    display: flex;    
    border-radius: 10px;
    margin-top: 15px;
    align-items: center;
}
.containerAberto{
    width: 99%;
    background: #FF1C49;
    display: flex;    
    border-radius: 10px;
    margin-top: 15px;
    align-items: center;

}
.data{
     max-width: 200px;
    width: 100%;
    height: 100%;    
    padding: 25px;
    
}
.descricao{     
    width: 100%;
    height: 100%;
    border-left: 3px solid #000;
    padding: 25px;
    
}

span{
    color: #fff;
    font-size: 25px;
}
 ::-webkit-scrollbar{
     width: 10px;   
 }
 ::-webkit-scrollbar-track{
     background-color: #000;
 }
  ::-webkit-scrollbar-thumb{
     background-color: #000;
     border-radius: 20px;
}
 ::-webkit-scrollbar-thumb:hover{
     background-color: #000;
}
@media (max-width: 1800px){
    max-height: 341px;

}
@media (max-width: 900px){ 
    span{
        font-size: 20px;
    }

}
@media (max-width: 551px){
    span{
        font-size: 17px;
    }

}


`



function AvisoComponet({ arr = {} }) {
 

    return (
        <AvisoWrapper>
            {
                arr.map((i,index) =>(
                    <div className={i.flg_aberto == 1 ? `containerAberto` : `containerAviso` }>
                        <div className="data">
                            <span>{i.data}</span>

                        </div>
                        <div className="descricao">
                            <span>{i.descricao}</span>
                        </div>
                    </div>

                ))
            }
       

        </AvisoWrapper >
    )
}

export default AvisoComponet