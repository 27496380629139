import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../fotos/logo/logo_degrade_quente_linhas_brancas.svg"
import CircularIndeterminate from "../../components/loading";
import DensityMediumIcon from '@mui/icons-material/DensityMedium';


const HeaderWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
margin-bottom: 10px;
padding: 14px 0px;
.containerIcon{
    display: none;
}

.containerLogo{
    display: flex;
    margin-bottom: 34px;
    
}
img{
    width: 130px;
}
.contentButton{
    display: flex;
    justify-content: space-between;
}
.left{
    display: flex;
    justify-content: start;
    max-width: 240px;
    width: 100%;
    align-items: center;
}
.left .button{
  //  max-width: 150px;
   // width: 100%;
   background-image: linear-gradient(to right, #F49519, #D35D2E );
    padding: 5px 9px;
    border-radius: 9px;
    text-align: center;
    cursor: pointer;
    margin-right: 15px;
}
.left .button span{
     text-transform: uppercase;
    color: #fff;
    
}

.right{
    display: flex;
    justify-content: space-around;
    max-width: 230px;
    width: 100%;
    align-items: center;
}
.right .button{
  //  max-width: 150px;
   // width: 100%;
    background-image: linear-gradient(to right, #F49519, #D35D2E );
    padding: 5px 9px;
    border-radius: 9px;
    text-align: center;
    cursor: pointer;
    
}
.right .button span{
     text-transform: uppercase;
    color: #fff;
    
}
.right .buttonExit{  
    background-color: #000;
    border: 1px solid #fff;
    padding: 5px 9px;
    border-radius: 9px;
    text-align: center;
    cursor: pointer;
}
.right .buttonExit span{
     text-transform: uppercase;
    color: #fff;
    
}
a{
    text-decoration: none;
}
.containerBackdrop{
    display: flex;
    width: 100%;
    position: absolute;
    alignItems: center;
    justifyContent: center;
    background: #000;
    height: 100%;
    top: 0;
    opacity: 0.7;
    z-index: 999999999;
    left: 0;
    justify-content: center;
    align-items: center;
}
@media (max-width: 1800px){
    .containerLogo{
        margin-bottom: 25px;
    }

}
@media (max-width: 1024px){
    max-width: 888px;

}
@media (max-width: 959px){
    max-width: 809px;

}
@media (max-width: 979px){
    max-width: 685px;

}
@media (max-width: 768px){
    max-width: 577px;

}
@media (max-width: 768px){
    max-width: 577px;

}
@media (max-width: 599px){
    max-width: 577px;

    .voltar{
        display: none;
    }
    .aviso{
        display: none;
    }
    .contentAluno{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .containerIcon{
        display: flex;
        background-image: linear-gradient(to right, #F49519, #D35D2E );
        padding: 5px;
        border-radius: 9px;
        margin-right: 10px;
    }
    .containerIcon .css-i4bv87-MuiSvgIcon-root{
        color: #fff;
        
    }
    .left .button{
        padding: 9px 15px;

    }


}
@media (max-width: 405px){
    font-size: 10px;
    max-width: 275px;
    .left .button {
        padding: 9px 5px;
    }

}


`


function Header({ loadingSet = false, visibleBack = true }) {
    
    const retornar = () =>{        
        window.history.go(-1);
       
    }
    const logout = () =>{
        localStorage.removeItem("token");
        window.location.href = "/autenticacao";

    }
    return (
        <>
            <HeaderWrapper>              
                <div className="contentButton">
                    <div className="left">
                        <a href="/">
                            <div className="contentAluno">
                                
                                <div className="button areaAluno">
                                    <span>Inicio</span>
                                </div>
                                
                            </div>
                      
                        </a>
                        {
                            visibleBack &&
                            <div className="button voltar" onClick={retornar}>
                                <span>Voltar</span>
                            </div>

                        }
                        
                        
                    </div>

                    <div className="right">
                   
                        <div className="button aviso">
                            <a href="/mudar-senha">
                                <span>Trocar de Senha</span>

                            </a>
                        </div>
                  
                        <div className="buttonExit sair" onClick={logout}>
                            <span>Sair</span>
                        </div>
                    </div>

                </div>
                {
                    loadingSet &&
                    <div className="containerBackdrop">
                        <CircularIndeterminate />
                    </div>
                }


            </HeaderWrapper>

        </>
    )
}

export default Header