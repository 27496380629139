import React from "react";
import styled from "styled-components";

const BibliotecaComponentWrapper = styled.div`
width: 100%;
height: 100%;    
overflow-y: auto;
max-height: 470px;
min-height: 272px;
display: flex;
justify-content: space-evenly;
align-items: center;

.botaoDireita{
     background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    width: 100%;
    padding: 15px;
    height: 50px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.botaoEsquerdo{
    background-image: linear-gradient(to right,  #D35D2E, #F49519 );
    width: 100%;
    padding: 15px;
    height: 50px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

}
span{
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}
a{
    text-decoration: none;
    
}


`



function BibliotecaComponente() {

    return (
        <BibliotecaComponentWrapper>
            <a className="botaoDireita" href="https://melies.odilo.us/" target="_blank">
                <span>Biblioteca Digital</span>
            </a>
          {/*   <a className="botaoEsquerdo" href="https://melies-aprendizagem.odilo.us/#!/" target="_blank">
                <span>Experiências de Aprendizagem </span>

            </a> */}


        </BibliotecaComponentWrapper>
    )


}

export default BibliotecaComponente