import React, { useState, useEffect } from "react"
import Banner from "../../components/banner";
import IconButton from "../../components/iconsButton";
import IconFive from "../../fotos/icones/brancos/brancos-05.png"
import styled from "styled-components";
import IcoNota from "../../fotos/icones/brancos/brancos-02.png"
import FreqComponent from "../../components/frequencia";
import axios from "axios";
import Header from "../../components/component";
const PageWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 30px;

    .containerItems{       
        display: flex;            
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }
      
@media (max-width: 1024px){
  .containerItems{  
      max-height: 389px;
      width: 96%;
  }

}
@media (max-width: 979px){
    width: 100%;
    max-width: 685px;

}
@media (max-width: 959px){
    align-items: center;
  .containerItems{
    justify-content: center;  
      
  }

}
@media (max-width: 768px){   
    align-items: center; 
     max-width: 574px;
        
  .containerItems{
    justify-content: flex-start;
    width: 98%;
    //margin-left: 40px;
      
  }

}
@media (max-width: 599px){       
    max-width: 420px;
     padding: 0px 0px;
     .containerItems{
        margin-left: 25px;
        max-height: 250px;
        min-height: 200px;
     }
     
    

}
@media (max-width: 405px){  
    max-width: 317px;
}
  
`


function Frequencia() {   
    const [nomeTurma, setNome] = useState("")
    const [freq, setFreq] = useState([])
    const token = localStorage.getItem("token")

    const pegarFrequencia = async (disciplinas,turmasMat) => {
        let arr = []
        let outrArr = []

        
        await axios.get(`${process.env.REACT_APP_API}/frequencia `, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {}, turmas = {} } = response.data   
                
            await disciplinas.map((item, index) => {
                item.map((elemnt) => {
                    let teste = data.filter((item) => item.cd_disciplina == elemnt.cd_disciplina && item.cd_curso == elemnt.cd_curso)
                    arr.push(teste)
                    
                })

            })
            await turmasMat.map((item) =>{
                let outrTeste =   data.filter((i) => i.cd_curso == item.cd_curso)                
                outrArr.push(outrTeste)
            })
            await setFreq(outrArr)


        }).catch(error => {
            const { response = {} } = error
            const { status = 0 } = response
            if (status == 401) {
                console.log("erro", error)
                window.location.href = "/autenticacao";
            }
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }

    const pegarNotas = async () => {
        let arr = []

        await axios.get(`${process.env.REACT_APP_API}/notas `, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET',
                'Access-Control-Allow-Headers': '*',
            },
        }).then(async response => {
            const { data = {}, turmas = {} } = response.data
            console.log("aqui",turmas)
            await turmas.map((item) => {
                let teste = data.filter((i) => {
                    if (i.nm_plano_avaliacao == item.nm_plano_avaliacao) {
                        return i;
                    }
                })
                arr.push(teste)

            });

            await setNome(turmas[0].nome)
            await pegarFrequencia(arr,turmas)


        }).catch(error => {
            const { response = {} } = error
            const { status = 0 } = response
            if (status == 401) {
                console.log("erro", error)
                window.location.href = "/autenticacao";
            }
            // setAlert({ titulo: 'Error: contact o suporte', visible: true, success: false })

        })
    }


    useEffect(() => {
        pegarNotas()


    }, [])





    return (
        <>
            <PageWrapper>
                <Header />
                <Banner button="Frequencia" img={IconFive} />
                <div className="containerItems">
                    <FreqComponent arr={freq} nomeTurma={nomeTurma} />



                </div>

            </PageWrapper>

        </>
    );

};

export default Frequencia